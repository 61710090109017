import React, { ReactElement, useState } from "react";
import { SelfWorkActionDetailResponse } from "../../models/SelfWorkActionApi";
import { UserResponse } from "../../models/User";
import RootCommentItem from "../comments/RootCommentItem";
import SelfWorkActionCardBodyItem from "./SelfWorkActionCardBodyItem";
import SelfWorkActionCardHeaderItem from "./SelfWorkActionCardHeaderItem";
import SelfWorkActionCardReactionItem from "./SelfWorkActionCardReactionItem";

interface Props {
  selfWorkAction: SelfWorkActionDetailResponse;
  currentUser: UserResponse;
  canPushDetail?: boolean;
  detailPagePath?: string; // canPushDetailがtrueの場合は必須
  editPagePath?: string;
  hideUserProfile?: boolean;
  searchWords?: string[];
  hideComment?: boolean;
  showReplies?: boolean;
}

export default function SelfWorkActionCard(props: Props): ReactElement | null {
  const {
    selfWorkAction,
    searchWords = [],
    detailPagePath,
    editPagePath,
    currentUser,
    canPushDetail = false,
    hideUserProfile = false,
    hideComment = false,
    showReplies = false,
  } = props;
  const [comments, setComments] = useState(selfWorkAction.comments);
  const [isBlocked, setIsBlocked] = useState(false);

  if (isBlocked) {
    return null;
  }

  return (
    <>
      <div className="mt-2 shadow px-2 pt-2">
        {!hideUserProfile && (
          <SelfWorkActionCardHeaderItem
            selfWorkAction={selfWorkAction}
            currentUser={currentUser}
            editPagePath={editPagePath}
            afterBlocked={() => {
              setIsBlocked(true);
            }}
          />
        )}
        <SelfWorkActionCardBodyItem
          selfWorkAction={selfWorkAction}
          searchWords={searchWords}
          detailPagePath={detailPagePath}
          canPushDetail={canPushDetail}
        />
        <SelfWorkActionCardReactionItem
          selfWorkAction={selfWorkAction}
          currentUser={currentUser}
          detailPagePath={detailPagePath}
          canPushDetail={canPushDetail}
          onReply={(c) => {
            if (canPushDetail) {
              window.Alert.success("コメントの送信が完了しました");
            } else {
              window.Alert.success("コメントの送信が完了しました");
              setComments(comments.concat([c]));
            }
          }}
        />
      </div>
      {!hideComment && (
        <>
          {comments.length === 0 && (
            <div className="p-2 mt-2 text-sm text-base-300">
              コメントはまだありません
            </div>
          )}
          <div className="mt-2 p-2">
            {comments.map((c) => (
              <div key={c.id} className="mt-2">
                <RootCommentItem
                  comment={c}
                  currentUser={currentUser}
                  showReplies={showReplies}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}
