import classnames from "classnames";
import React, { ReactElement, useRef, useState } from "react";
import { FileResourceEditType } from "../../../models/FileResource";
import { UserResponse } from "../../../models/User";
import { uploadToAws } from "../../../utils/imageUtils";
import Button from "../Button";
import ImageGrid from "../ImageGrid";
import Loading from "../Loading";
import HiddenForm from "./HiddenForm";

interface Props {
  model: {
    file_resources: FileResourceEditType[];
  };
  scope: string;
  currentUser: UserResponse;
  multiple?: boolean;
  hideLabel?: boolean;
  hideBottomBorder?: boolean;
}

export default function FileResourceImageForm(props: Props): ReactElement {
  const {
    model,
    scope,
    currentUser,
    multiple = false,
    hideLabel = false,
    hideBottomBorder = false,
  } = props;
  const [uploading, setUploading] = useState(false);
  const [fileResources, setFileResources] = useState<FileResourceEditType[]>(
    model.file_resources
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const upload = async (files: FileList) => {
    if (uploading) {
      return;
    }
    setUploading(true);
    const res = await Promise.all(Array.from(files).map((f) => uploadToAws(f)));
    const results = res
      .map((r) => r.result)
      .filter((r) => r !== undefined) as Array<{
      url: string;
      key: string;
      tempUrl: string;
    }>;
    const oldFileResources = fileResources.map((fr) =>
      Object.assign({}, fr, { _destroy: "1" })
    ) as FileResourceEditType[];
    const newFileResources = results.map((r) => ({
      key: r.key,
      url: r.tempUrl,
    }));
    setFileResources(oldFileResources.concat(newFileResources));
    setUploading(false);
  };

  const activeFileResources = fileResources.filter(
    (fr) => fr._destroy === undefined || fr._destroy === "0"
  );

  let formContainerClassName = "flex flex-wrap";
  if (!hideBottomBorder) {
    formContainerClassName = classnames(
      formContainerClassName,
      "border-b",
      "border-gray-300"
    );
  }

  return (
    <>
      {fileResources.map((fr, i) => (
        <div key={fr.key}>
          {fr.id !== undefined && (
            <HiddenForm
              scope={scope}
              relatedModelName="file_resources"
              index={i}
              columnName="id"
              value={fr.id}
            />
          )}
          <HiddenForm
            scope={scope}
            relatedModelName="file_resources"
            index={i}
            columnName="key"
            value={fr.key}
          />
          <HiddenForm
            scope={scope}
            relatedModelName="file_resources"
            index={i}
            columnName="user_id"
            value={currentUser.id}
          />
          {fr._destroy !== undefined && (
            <HiddenForm
              scope={scope}
              relatedModelName="file_resources"
              index={i}
              columnName="_destroy"
              value={currentUser.id}
            />
          )}
        </div>
      ))}
      <div>
        <div className={formContainerClassName}>
          {!hideLabel && (
            <label className="w-full md:w-1/3 px-2 py-3">画像</label>
          )}
          {uploading && (
            <div className="w-full">
              <Loading />
            </div>
          )}
          {!uploading && activeFileResources.length > 0 && (
            <div className="flex justify-center w-full">
              <ImageGrid imageUrls={activeFileResources.map((fr) => fr.url)} />
            </div>
          )}
          <div className="w-full md:w-full px-2 py-3 flex justify-end md:justify-center">
            <Button
              title="画像選択"
              color="base-300"
              onClick={() => {
                if (inputRef.current !== null) {
                  inputRef.current.click();
                }
              }}
            />
            <input
              ref={inputRef}
              type="file"
              onChange={(e) => {
                const files = e.target.files;
                if (files !== null) {
                  upload(files).catch((e) => console.log(e));
                  // 同じファイルを選択したときにonChangeに発火しないのでstate登録後すぐにクリア
                  e.target.value = "";
                }
              }}
              accept="image/*"
              style={{ display: "none" }}
              multiple={multiple}
            />
          </div>
        </div>
      </div>
    </>
  );
}
