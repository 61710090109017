import Terrible from "../assets/images/terrible.png";
import VeryVad from "../assets/images/very_bad.png";
import Bad from "../assets/images/bad.png";
import Average from "../assets/images/average.png";
import Good from "../assets/images/good.png";
import VeryGood from "../assets/images/very_good.png";
import Excellent from "../assets/images/excellent.png";
import { FileResourceEditType } from "./FileResource";

export interface SelfWorkActionResponse {
  id: number;
  result: string;
  state: string;
  save_state: string;
  happiness: string;
  planned_date: string;
  actual_date: string;
  completed_at: string;
  continuous_days: number;
  success_action_count: number;
  body: string;
  happy_ai_comment_flg: boolean;
  updated_at: string;
  created_at: string;
}

export interface SelfWorkActionEditType {
  id: number | null;
  result: "successed" | "failed";
  state: "closed" | "opened" | "friend_opened";
  save_state?: "temp" | "permanent";
  happiness: string;
  planned_date: string | null;
  actual_date: string | null;
  completed_at: string | null;
  continuous_days: number;
  body: string | null;
  happy_ai_comment_flg: boolean;
  file_resources: FileResourceEditType[];
  good_thing_details: Array<{
    id: number | null;
    body: string;
    _destroy?: "0" | "1";
  }>;
}

export interface SelfWorkActionMarkedIconType {
  start: string;
  end: string;
  happinesses: string[];
}

export function happinessImagePath(happiness: string): string | null {
  switch (happiness) {
    case "unset_happiness":
      return null;
    case "terrible":
      return Terrible;
    case "very_bad":
      return VeryVad;
    case "bad":
      return Bad;
    case "normal":
      return Average;
    case "good":
      return Good;
    case "very_good":
      return VeryGood;
    case "excellent":
      return Excellent;
    default:
      return null;
  }
}
