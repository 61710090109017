import React, { ReactElement, ReactNode } from "react";
import { fieldId, fieldName, i18nKey } from "../../../utils/formAttributeUtils";
import { i18n } from "../../../utils/i18nUtils";
import SelectField from "./fields/SelectField";

interface Props {
  model: any;
  scope: string;
  columnName: string;
  relatedModelName?: string;
  options: Array<{ key: string; value: string }>;
  option?: { include_blank: string };
  label?: string;
  labelColor?: string;
  index?: number;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedKey?: string;
  defaultKey?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  required?: boolean;
  message?: string;
  bellowComponent?: ReactNode;
}

export default function SelectForm(props: Props): ReactElement {
  const {
    model,
    scope,
    relatedModelName = "",
    columnName,
    options,
    option,
    labelColor,
    index,
    onChange,
    defaultKey = "",
    disabled,
    hideLabel = false,
    selectedKey,
    required = false,
    message,
    label = "",
    bellowComponent,
  } = props;

  const viewLabel = (): string => {
    if (label !== "") {
      return label;
    }
    return i18n.t(i18nKey(scope, relatedModelName, columnName));
  };

  const fieldKey = (): string => {
    if (selectedKey !== undefined) {
      return selectedKey;
    } else if (model[columnName] !== undefined) {
      return model[columnName];
    }
    return defaultKey;
  };

  return (
    <div>
      <div className="flex flex-wrap border-b border-gray-300">
        {!hideLabel && (
          <label
            className="w-full md:w-1/3 px-2 py-3"
            htmlFor={fieldId(scope, relatedModelName, columnName, index)}
            style={{ color: labelColor }}
          >
            {viewLabel()}
            {required && <span> ※ 必須</span>}
          </label>
        )}
        <div className="w-full md:w-2/3 px-2 py-3 flex justify-end">
          <SelectField
            id={fieldId(scope, relatedModelName, columnName, index)}
            name={fieldName(scope, relatedModelName, columnName, index)}
            options={options}
            selectedKey={fieldKey()}
            option={option}
            onChange={(e) => {
              if (onChange !== undefined) {
                onChange(e);
              }
            }}
            disabled={disabled}
            required={required}
          />
          {message !== undefined && (
            <div className="color__gray font__size-13">{message}</div>
          )}
        </div>
        {bellowComponent}
      </div>
    </div>
  );
}
