import classnames from "classnames";
import React, { ReactElement, ReactNode } from "react";
import Button, { Props as ButtonProps } from "./Button";

interface Props extends ButtonProps {
  submitId: string;
}

export default function ButtonWithSubmit(props: Props): ReactElement {
  const {
    submitId,
    title,
    color,
    opacity,
    icon,
    onClick,
    active,
    size,
    border,
    full,
    rounded,
    children,
    disabledTitle,
    disabled,
  } = props;

  return (
    <>
      <Button
        title={title}
        color={color}
        opacity={opacity}
        icon={icon}
        onClick={onClick}
        active={active}
        size={size}
        border={border}
        full={full}
        rounded={rounded}
        children={children}
        disabledTitle={disabledTitle}
        disabled={disabled}
      />
      <button
        type="submit"
        id={submitId}
        style={{ border: "none", padding: 0 }}
      />
    </>
  );
}
