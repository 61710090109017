import React, { ReactElement, useState } from "react";
import { HappyAiResponse } from "../../models/HappyAi";
import { SelfWorkActionEditType } from "../../models/SelfWorkAction";
import { UserResponse } from "../../models/User";
import SelfWorkActionDefaultHappyAiEditButton from "../self_work_action_defaults/SelfWorkActionDefaultHappyAiEditButton";
import BaseForm from "../shared/BaseForm";
import DateForm from "../shared/forms/DateForm";
import FileResourceImageForm from "../shared/forms/FileResourceImageForm";
import HiddenForm from "../shared/forms/HiddenForm";
import SelectForm from "../shared/forms/SelectForm";
import HappinessForm from "./HappinessForm";
import SelfWorkActionGoodThingsForm from "./SelfWorkActionGoodThingsForm";

export interface Props {
  selfWorkAction: SelfWorkActionEditType;
  currentUser: UserResponse;
  stateOptions: Array<{ key: string; value: string }>;
  updatePath?: string;
  createPath?: string;
  happyAis: HappyAiResponse[];
  releasedHappyAis: HappyAiResponse[];
  defaultHappyAi: HappyAiResponse;
  defaultHappyAiUpdatePath: string;
  defaultHappyAiEditSubmitButtonId: string;
}

export default function SelfWorkActionEditForm(props: Props): ReactElement {
  const {
    selfWorkAction: propSelfWorkAction,
    currentUser,
    stateOptions,
    updatePath,
    createPath,
    happyAis,
    releasedHappyAis,
    defaultHappyAi,
    defaultHappyAiUpdatePath,
    defaultHappyAiEditSubmitButtonId,
  } = props;
  const [selfWorkAction, setSelfWorkAction] = useState(propSelfWorkAction);

  let actionPath = "";
  let isCreate = false;
  if (createPath !== undefined) {
    actionPath = createPath;
    isCreate = true;
  } else if (updatePath !== undefined) {
    actionPath = updatePath;
  }

  return (
    <BaseForm
      action={actionPath}
      name="selfWorkActionEditForm"
      method={isCreate ? "post" : "put"}
    >
      <div className="bg-gray-200 p-2 shadow-b">
        <div className="max-w-screen-lg flex justify-end px-2 mx-auto">
          <input
            type="submit"
            value={isCreate ? "登録" : "更新"}
            className="bg-primary text-white px-3 py-2 rounded cursor-pointer"
            data-disable-with={isCreate ? "登録中.." : "更新中.."}
            // disabled
          />
        </div>
      </div>
      <div className="max-w-screen-lg px-2 mx-auto">
        {selfWorkAction.actual_date != null && (
          <HiddenForm
            scope="self_work_action"
            columnName="actual_date"
            value={selfWorkAction.actual_date}
          />
        )}
        <DateForm
          model={selfWorkAction}
          scope="self_work_action"
          columnName="planned_date"
          onChange={(dateStr) =>
            setSelfWorkAction(
              Object.assign({}, selfWorkAction, {
                planned_date: dateStr,
                actual_date: dateStr,
              })
            )
          }
        />
        <HappinessForm selfWorkAction={selfWorkAction} />
        <SelectForm
          model={selfWorkAction}
          scope="self_work_action"
          columnName="state"
          options={stateOptions}
        />
        <SelectForm
          model={selfWorkAction}
          scope="self_work_action"
          columnName="happy_ai_comment_flg"
          selectedKey={String(selfWorkAction.happy_ai_comment_flg)}
          options={[
            { key: "true", value: "受け取る" },
            { key: "false", value: "受け取らない" },
          ]}
          bellowComponent={
            <div className="flex justify-end w-full">
              <SelfWorkActionDefaultHappyAiEditButton
                happyAis={happyAis}
                releasedHappyAis={releasedHappyAis}
                defaultHappyAi={defaultHappyAi}
                updatePath={defaultHappyAiUpdatePath}
                submitButtonId={defaultHappyAiEditSubmitButtonId}
              />
            </div>
          }
        />
        <SelfWorkActionGoodThingsForm selfWorkAction={selfWorkAction} />
        <FileResourceImageForm
          model={selfWorkAction}
          scope="self_work_action"
          multiple
          currentUser={currentUser}
        />
      </div>
      <div className="w-full flex justify-center mt-10">
        <input
          type="submit"
          value={isCreate ? "登録" : "更新"}
          className="bg-primary text-white px-3 py-2 rounded cursor-pointer w-1/2"
          data-disable-with={isCreate ? "登録中.." : "更新中.."}
          // disabled
        />
      </div>
    </BaseForm>
  );
}
