/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import "../css/tailswind.css";

const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
const axios = require("axios");

ReactRailsUJS.useContext(componentRequireContext);

const csrfToken = Array.prototype.slice
  .call(document.head.children)
  .filter((headerTag) => headerTag.getAttribute("name") === "csrf-token")[0]
  .getAttribute("content");
axios.defaults.headers["X-CSRF-TOKEN"] = csrfToken;
axios.defaults.timeout = 30000;

registerLocale("ja", ja);
setDefaultLocale("ja");
