import React, { ReactElement } from "react";
import Loading from "./Loading";

interface Props {
  show: boolean;
}

export default function ScreenLoading(props: Props): ReactElement | null {
  const { show } = props;
  if (!show) {
    return null;
  }
  return (
    <div className="fixed inset-0 flex items-center z-50">
      <div className="w-full flex justify-center">
        <Loading />
      </div>
    </div>
  );
}
