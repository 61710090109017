import React, { ReactElement } from "react";
import LoadingImage from "../../assets/images/spin-gray.svg";

export default function Loading(): ReactElement {
  return (
    <div className="relative w-full h-16">
      <object
        type="image/svg+xml"
        data={LoadingImage}
        width="40"
        height="40"
        title="loading"
        className="absolute top-0"
        style={{
          transform: "translate(-50%)",
          left: "50%",
        }}
      />
    </div>
  );
}
