import React, { CSSProperties, ReactElement, ReactNode } from "react";

interface Props {
  children: ReactNode;
  fixed?: boolean;
  style?: CSSProperties;
}

export default function BaseModalBody(props: Props): ReactElement {
  const { children, fixed = false, style = {} } = props;
  const s = Object.assign(
    {},
    fixed ? { height: "53vh" } : { maxHeight: "53vh" },
    style
  );
  return (
    <div
      className="p-3"
      style={Object.assign(
        {
          overflow: "auto",
        },
        s
      )}
    >
      {children}
    </div>
  );
}
