import React, { ReactElement, useEffect, useState } from "react";
import { postRequest } from "../../utils/apiClient";
import { FriendUserAcceptInvitationResponse } from "../../models/FriendUserApi";
import MessageModal from "../shared/MessageModal";

interface Props {
  friendInviteToken: string;
}

export default function FriendUserInvitationHandler(
  props: Props
): ReactElement {
  const { friendInviteToken } = props;
  const [inviter, setInviter] = useState<FriendUserAcceptInvitationResponse>();

  useEffect(() => {
    if (friendInviteToken !== "") {
      acceptFriendInvitation();
    }
  }, [friendInviteToken]);

  async function acceptFriendInvitation(): Promise<void> {
    const { result } = await postRequest<FriendUserAcceptInvitationResponse>(
      "/api/friend_users/invitation_create_by_invite_token",
      {
        friend_invite_token: friendInviteToken,
      }
    );
    if (result !== undefined) {
      setInviter(result);
    } else {
      setInviter(undefined);
    }
  }

  return (
    <div>
      {inviter !== undefined && (
        <MessageModal
          isVisible={true}
          title={`${inviter.user.account_name}さんと友達になりました`}
          messages={[
            `友達限定で登録された${inviter.user.account_name}さんの3つの良いことがタイムラインで閲覧できるようになりました`,
          ]}
          onClose={() => {
            setInviter(undefined);
          }}
        />
      )}
    </div>
  );
}
