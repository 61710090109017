import React, { ReactElement } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, format, parseISO } from "date-fns";
import HiddenField from "./fields/HiddenField";
import { fieldId, fieldName, i18nKey } from "../../../utils/formAttributeUtils";
import { i18n } from "../../../utils/i18nUtils";

interface Props {
  model: any;
  scope: string;
  relatedModelName?: string;
  columnName: string;
  label?: string;
  onChange?: (date: string) => void;
}

export default function DateForm(props: Props): ReactElement {
  const {
    model,
    label = "",
    scope,
    relatedModelName = "",
    columnName,
    onChange,
  } = props;

  const selectedDate = parseISO(model[columnName]);

  const viewLabel = (): string => {
    if (label !== "") {
      return label;
    }
    return i18n.t(i18nKey(scope, relatedModelName, columnName));
  };

  return (
    <div>
      <HiddenField
        id={fieldId(scope, relatedModelName, columnName)}
        name={fieldName(scope, relatedModelName, columnName)}
        value={format(selectedDate, "yyyy-MM-dd")}
      />
      <div className="flex flex-wrap border-b border-gray-300">
        <label
          className="w-full md:w-1/3 px-2 py-3"
          htmlFor={fieldId(scope, relatedModelName, columnName)}
        >
          {viewLabel()}
        </label>
        <div className="w-full md:w-2/3 px-2 py-3 flex justify-end">
          <DatePicker
            className="cursor-pointer"
            selected={selectedDate}
            onChange={(date) => {
              if (onChange !== undefined && date != null) {
                onChange(format(date as Date, "yyyy-MM-dd"));
              }
            }}
            dateFormat="yyyy年MM月dd日(EEEEE)"
            dateFormatCalendar="yyyy年MM月"
            excludeDateIntervals={[
              { start: new Date(), end: addDays(new Date(), 3000) },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
