import React, { ReactElement } from "react";
import { UserResponse } from "../../models/User";
import BaseForm from "../shared/BaseForm";
import ImageForm from "../shared/forms/ImageForm";
import TextForm from "../shared/forms/TextForm";

interface Props {
  currentUser: UserResponse;
}

export default function UserEditForm(props: Props): ReactElement {
  const { currentUser } = props;

  return (
    <BaseForm action="/c_user" name="userEditForm" method="put">
      <div className="bg-gray-200 p-2 shadow-b">
        <div className="max-w-screen-lg flex justify-end px-2 mx-auto">
          <input
            type="submit"
            value="更新"
            className="bg-primary text-white px-3 py-2 rounded cursor-pointer"
            data-disable-with="更新中.."
            // disabled
          />
        </div>
      </div>
      <div className="max-w-screen-lg p-2 mx-auto">
        <div>
          <TextForm
            model={currentUser}
            scope="user"
            columnName="account_name"
          />
        </div>
        <div>
          <ImageForm
            model={currentUser}
            scope="user"
            columnName="profile_image_key"
            defaultUrl={currentUser.profile_image_url}
            previewComponent={(url) => (
              <div className="flex justify-center w-full">
                <img
                  src={url}
                  alt="account_profile"
                  className="w-16 h-16 rounded-full"
                />
              </div>
            )}
          />
        </div>
      </div>
      <div className="w-full flex justify-center mt-10">
        <input
          type="submit"
          value="更新"
          className="bg-primary text-white px-3 py-2 rounded cursor-pointer w-1/2"
          data-disable-with="更新中.."
          // disabled
        />
      </div>
    </BaseForm>
  );
}
