export interface NotificationSettingResponse {
  id?: number;
  role: "remind_action" | "recieve_good_reaction";
  state: "disabled" | "activated" | "scheduled";
  notify_monday_flg: string;
  notify_tuesday_flg: string;
  notify_wednesday_flg: string;
  notify_thursday_flg: string;
  notify_friday_flg: string;
  notify_saturday_flg: string;
  notify_sunday_flg: string;
  notification_time: string | null;
}

export function recieveGoodReactionDefaultValue(): NotificationSettingResponse {
  return {
    role: "recieve_good_reaction",
    state: "disabled",
    notify_monday_flg: "0",
    notify_tuesday_flg: "0",
    notify_wednesday_flg: "0",
    notify_thursday_flg: "0",
    notify_friday_flg: "0",
    notify_saturday_flg: "0",
    notify_sunday_flg: "0",
    notification_time: null,
  };
}

export function remindActionDefaultValue(): NotificationSettingResponse {
  return {
    role: "remind_action",
    state: "disabled",
    notify_monday_flg: "1",
    notify_tuesday_flg: "1",
    notify_wednesday_flg: "1",
    notify_thursday_flg: "1",
    notify_friday_flg: "1",
    notify_saturday_flg: "1",
    notify_sunday_flg: "1",
    notification_time: "21:00",
  };
}
