function convToIdFormat(scope): string {
  return scope.replace("][", "_").replace("[", "_").replace("]", "");
}

function rootScope(scope): string {
  return scope.replace(/\[.*/, "");
}

export function baseScope(
  scope: string,
  relatedModelName: string,
  index = -1
): string {
  if (relatedModelName !== "") {
    if (index >= 0) {
      return `${scope}[${relatedModelName}_attributes][${index}]`;
    }
    return `${scope}[${relatedModelName}_attributes]`;
  }
  return scope;
}

export function fieldId(
  scope: string,
  relatedModelName: string,
  columnName: string,
  index = -1
): string {
  const scopeId = convToIdFormat(scope);
  if (relatedModelName !== "") {
    if (index >= 0) {
      return `${scopeId}_${relatedModelName}_attributes_${index}_${columnName}`;
    }
    return `${scopeId}_${relatedModelName}_attributes_${columnName}`;
  }
  return `${scopeId}_${columnName}`;
}

export function fieldName(
  scope: string,
  relatedModelName: string,
  columnName: string,
  index = -1
): string {
  if (relatedModelName !== "") {
    if (index >= 0) {
      return `${scope}[${relatedModelName}_attributes][${index}][${columnName}]`;
    }
    return `${scope}[${relatedModelName}_attributes][${columnName}]`;
  }
  return `${scope}[${columnName}]`;
}

export function i18nKey(
  scope: string,
  relatedModelName: string,
  columnName: string
): string {
  if (relatedModelName !== "") {
    return `activerecord.attributes.${relatedModelName}.${columnName}`;
  }
  return `activerecord.attributes.${rootScope(scope)}.${columnName}`;
}
