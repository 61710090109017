import React, { ReactElement } from "react";
import UserDummyImage from "../../assets/images/user_dummy.png";
import { UserResponse } from "../../models/User";

interface Props {
  user: UserResponse;
}

export default function UserProfileImage(props: Props): ReactElement {
  const {
    user: { profile_image_url: profileImageUrl },
  } = props;

  if (profileImageUrl === null || profileImageUrl === "") {
    return (
      <img
        src={UserDummyImage}
        alt="account profile image"
        className="w-10 h-10 rounded-full"
      />
    );
  }
  return (
    <img
      src={profileImageUrl}
      alt="account_profile"
      className="w-10 h-10 rounded-full object-contain"
    />
  );
}
