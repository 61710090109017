import React, { ReactElement, useState } from "react";
import { HappyAiResponse } from "../../models/HappyAi";
import { ajaxSubmit } from "../shared/BaseForm";
import BaseModal from "../shared/BaseModal";
import Button from "../shared/Button";
import SelfWorkActionDefaultHappyAiEditForm from "./SelfWorkActionDefaultHappyAiEditForm";

export interface Props {
  happyAis: HappyAiResponse[];
  releasedHappyAis: HappyAiResponse[];
  defaultHappyAi: HappyAiResponse;
  updatePath: string;
  submitButtonId: string;
  onCloseModal: () => void;
  afterSaved: (data: { defaultHappyAi: HappyAiResponse }) => void;
}

export default function SelfWorkActionDefaultHappyAiEditModal(
  props: Props
): ReactElement {
  const {
    happyAis,
    releasedHappyAis,
    defaultHappyAi,
    updatePath,
    submitButtonId,
    onCloseModal,
    afterSaved,
  } = props;
  const [loading, setLoading] = useState(false);

  const save = async () => {
    const form = document.getElementById(
      "selfWorkActionDefaultHappyAiEditForm"
    );
    if (!(form instanceof HTMLFormElement)) {
      return;
    }
    setLoading(true);
    const { result } = await ajaxSubmit<{ defaultHappyAi: HappyAiResponse }>(
      form
    );
    if (result !== undefined) {
      afterSaved(result);
    }
  };

  return (
    <BaseModal
      show
      title="ハッピーAI一覧"
      contentLabel="SelfWorkActionDefaultHappyAiEditModal"
      onCloseModal={() => onCloseModal()}
      loading={loading}
    >
      <BaseModal.Body>
        <div>
          <SelfWorkActionDefaultHappyAiEditForm
            happyAis={happyAis}
            releasedHappyAis={releasedHappyAis}
            defaultHappyAi={defaultHappyAi}
            updatePath={updatePath}
            submitButtonId={submitButtonId}
            formType="ajax"
          />
        </div>
      </BaseModal.Body>
      <BaseModal.Footer>
        <div className="flex justify-end">
          <Button active disabled={loading} title="更新" onClick={save} />
        </div>
      </BaseModal.Footer>
    </BaseModal>
  );
}
