import React, { ReactElement } from "react";
import { ReactionResponse } from "../../models/Reaction";
import { UserResponse } from "../../models/User";
import BaseModal from "../shared/BaseModal";
import BaseModalBody from "../shared/base_modal/BaseModalBody";
import UserProfileImage from "../users/UserProfileImage";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  reactions: Array<ReactionResponse & { user: UserResponse }>;
  currentUserReactedCount: number;
  currentUser: UserResponse;
}

export default function ReactionUsersModal(props: Props): ReactElement {
  const {
    isVisible,
    onClose,
    reactions,
    currentUserReactedCount,
    currentUser,
  } = props;

  function displayReactions(): Array<
    ReactionResponse & { user: UserResponse }
  > {
    if (currentUser == null) {
      return [];
    }
    if (reactions.some((r) => r.user.id === currentUser.id)) {
      return reactions.map((r) => {
        if (r.user.id === currentUser.id) {
          return Object.assign({}, r, { count: currentUserReactedCount });
        }
        return r;
      });
    } else if (currentUserReactedCount > 0) {
      return reactions.concat({
        id: -1,
        vary: "good",
        body: "",
        count: currentUserReactedCount,
        user: currentUser,
      });
    } else {
      return reactions;
    }
  }

  return (
    <BaseModal
      show={true}
      title="いいね!"
      contentLabel="ReactionUsersModal"
      onCloseModal={() => onClose()}
      onRequestClose={() => onClose()}
    >
      <BaseModalBody>
        <div>
          {displayReactions().map((r) => (
            <div
              key={r.id}
              className="flex justify-between items-center p-2 border-t"
            >
              <div className="flex items-center">
                <div>
                  <UserProfileImage user={r.user} />
                </div>
                <div className="ml-2">{r.user.account_name}</div>
              </div>
              <div className="text-accessory font-bold">
                <div>
                  <i className="fas fa-thumbs-up"></i>
                  <span className="ml-2">{r.count}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </BaseModalBody>
    </BaseModal>
  );
}
