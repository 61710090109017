import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const config = {
  apiKey: "AIzaSyDr45XxZX9azKSQzsk0ee3iCZ0BZmroo5I",
  authDomain: "happy-training.firebaseapp.com",
  databaseURL: "https://happy-training.firebaseio.com",
  projectId: "happy-training",
  storageBucket: "happy-training.appspot.com",
  messagingSenderId: "700673910356",
  appId: "1:700673910356:web:12d9e70a5a3f1ac5e7efc1",
  measurementId: "G-37DXSPPWEQ",
};
const firebaseApp = initializeApp(config);

export const messaging = getMessaging(firebaseApp);
export default firebaseApp;
