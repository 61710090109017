import React, { ReactElement, useState } from "react";
import { HappyAiResponse } from "../../models/HappyAi";
import BaseForm from "../shared/BaseForm";
import Button from "../shared/Button";
import HiddenForm from "../shared/forms/HiddenForm";

export interface Props {
  happyAis: HappyAiResponse[];
  releasedHappyAis: HappyAiResponse[];
  defaultHappyAi: HappyAiResponse;
  updatePath: string;
  submitButtonId: string;
  formType?: "ajax" | "normal";
}

function uniq(array: number[]): number[] {
  const temp: number[] = [];
  for (let i = 0; i < array.length; i += 1) {
    const element = array[i];
    if (!temp.includes(element)) {
      temp.push(element);
    }
  }
  return temp;
}

export default function SelfWorkActionDefaultHappyAiEditForm(
  props: Props
): ReactElement {
  const {
    happyAis,
    releasedHappyAis,
    defaultHappyAi,
    updatePath,
    submitButtonId,
    formType,
  } = props;
  const [selectedHappyAi, setSelectedHappyAi] = useState(defaultHappyAi);

  const totalCountList = uniq(
    happyAis.map((ha) => ha.release_condition_total_post_count)
  );

  return (
    <BaseForm
      action={updatePath}
      name="selfWorkActionDefaultHappyAiEditForm"
      method="put"
      type={formType}
    >
      <HiddenForm
        scope="self_work_action_default"
        columnName="happy_ai_id"
        value={selectedHappyAi.id}
      />
      <div className="mt-3">
        {totalCountList.map((count) => {
          return (
            <div key={count}>
              {count > 0 && (
                <div className="p-2 text-sm bg-base-100">{`${count}日間の投稿で獲得`}</div>
              )}
              {happyAis
                .filter((ha) => ha.release_condition_total_post_count === count)
                .map((ha) => {
                  const isReleased = releasedHappyAis.some(
                    (rha) => rha.id === ha.id
                  );
                  return (
                    <div
                      className="flex justify-between items-center p-3"
                      key={ha.id}
                    >
                      <div className="flex items-center">
                        <div>
                          <img
                            src={ha.icon_url}
                            className="w-8 h-8 object-contain rounded-full"
                          />
                        </div>
                        <div className="ml-2">{ha.name}</div>
                      </div>
                      <div>
                        {selectedHappyAi.id === ha.id && (
                          <div className="p-2 w-12">
                            <i className="fas fa-check"></i>
                          </div>
                        )}

                        {selectedHappyAi.id !== ha.id && (
                          <Button
                            title={isReleased ? "選択" : "未解放"}
                            disabled={!isReleased}
                            color="base-300"
                            onClick={() => {
                              setSelectedHappyAi(ha);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
      <button id={submitButtonId} type="submit" className="p-0 border-none" />
    </BaseForm>
  );
}
