import React, { ReactElement, useState } from "react";
import Switch from "react-switch";
import classnames from "classnames";
import HiddenForm from "./HiddenForm";

interface Props {
  model: any;
  scope: string;
  columnName: string;
  relatedModelName?: string;
  index?: number;
  activeValue: string;
  disableValue: string;
  label?: string;
  onChange?: (value: boolean) => void;
  noBorder?: boolean;
}

export default function SwitchForm(props: Props): ReactElement {
  const {
    model,
    scope,
    columnName,
    relatedModelName,
    index,
    activeValue,
    disableValue,
    label,
    onChange,
    noBorder = false,
  } = props;
  const modelValue = model[columnName];

  const [checked, setChecked] = useState(modelValue === activeValue);

  let className = "flex justify-between items-center p-3";
  if (!noBorder) {
    className = classnames(className, "border-b");
  }
  return (
    <>
      <HiddenForm
        scope={scope}
        columnName={columnName}
        relatedModelName={relatedModelName}
        index={index}
        value={checked ? activeValue : disableValue}
      />
      <div className={className}>
        <div>{label}</div>
        <div className="flex justify-end items-center">
          <div className="px-2">{checked ? "ON" : "OFF"}</div>
          <Switch
            onChange={(v) => {
              setChecked(v);
              if (onChange !== undefined) {
                onChange(v);
              }
            }}
            checked={checked}
          />
        </div>
      </div>
    </>
  );
}
