import { useState, useEffect } from 'react'

export default function useWindowDimensions (): { width: number, height: number} {
  const getWindowDimensions = (): { width: number, height: number} => {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  useEffect(() => {
    const onResize = (): void => {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])
  return windowDimensions
}
