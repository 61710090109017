import React, { ReactElement, useState } from "react";
import { CommentDetailResponse } from "../../models/CommentApi";
import { SelfWorkActionDetailResponse } from "../../models/SelfWorkActionApi";
import { UserResponse } from "../../models/User";
import SelfWorkActionCardBodyItem from "../self_work_actions/SelfWorkActionCardBodyItem";
import SelfWorkActionCardHeaderItem from "../self_work_actions/SelfWorkActionCardHeaderItem";
import BaseForm, { ajaxSubmit } from "../shared/BaseForm";
import BaseModal from "../shared/BaseModal";
import ButtonWithSubmit from "../shared/ButtonWithSubmit";
import BaseModalBody from "../shared/base_modal/BaseModalBody";
import BaseModalFooter from "../shared/base_modal/BaseModalFooter";
import FileResourceImageForm from "../shared/forms/FileResourceImageForm";
import TextAreaForm from "../shared/forms/TextAreaForm";
import UserProfileImage from "../users/UserProfileImage";

interface Props {
  selfWorkAction: SelfWorkActionDetailResponse;
  currentUser: UserResponse;
  onClose: () => void;
  onSaved: (editedComment: CommentDetailResponse) => void;
}

export default function SelfWorkActionCommentEditModal(
  props: Props
): ReactElement {
  const { selfWorkAction, currentUser, onClose, onSaved } = props;
  const [loading, setLoading] = useState(false);

  const save = async () => {
    const form = document.getElementById("SelfWorkActionCommentForm");
    if (!(form instanceof HTMLFormElement)) {
      return;
    }
    if (form.checkValidity()) {
      setLoading(true);
      const { result } = await ajaxSubmit<CommentDetailResponse>(form);
      if (result !== undefined) {
        onSaved(result);
      } else {
        setLoading(false);
      }
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      const submit = document.getElementById("selfWorkActionCommentFormSubmit");
      if (submit !== null) {
        submit.click();
      }
    }
  };

  return (
    <BaseModal
      show={true}
      loading={loading}
      title="コメント"
      contentLabel="SelfWorkActionCommentEditModal"
      onCloseModal={() => onClose()}
    >
      <BaseForm
        type="ajax"
        name="SelfWorkActionCommentForm"
        action={`/api/self_work_actions/${selfWorkAction.id}/comments`}
        method="post"
      >
        <BaseModalBody>
          <>
            <div className="border rounded p-2">
              <SelfWorkActionCardHeaderItem
                selfWorkAction={selfWorkAction}
                currentUser={currentUser}
                hideMenu
              />
              <SelfWorkActionCardBodyItem selfWorkAction={selfWorkAction} />
            </div>
            <div className="mt-3 flex">
              <div>
                <UserProfileImage user={currentUser} />
              </div>
              <div className="flex-grow pl-2">
                <TextAreaForm
                  model={{}}
                  scope="comment"
                  columnName="body"
                  rows={5}
                  hideLabel
                  placeholder="コメント"
                  focusWhenDisplayed
                  required
                />
                <div className="mt-2">
                  <FileResourceImageForm
                    model={{ file_resources: [] }}
                    scope="comment"
                    multiple
                    currentUser={currentUser}
                    hideLabel
                    hideBottomBorder
                  />
                </div>
              </div>
            </div>
          </>
        </BaseModalBody>
        <BaseModalFooter>
          <div className="flex justify-end">
            <ButtonWithSubmit
              title="送信"
              onClick={save}
              submitId="selfWorkActionCommentFormSubmit"
            />
          </div>
        </BaseModalFooter>
      </BaseForm>
    </BaseModal>
  );
}
