import React, { ReactElement } from "react";
import { happinessImagePath } from "../../models/SelfWorkAction";
import { SelfWorkActionDetailResponse } from "../../models/SelfWorkActionApi";

export interface Props {
  selfWorkAction: SelfWorkActionDetailResponse;
}

export default function SelfWorkActionHappiness(
  props: Props
): ReactElement | null {
  const { selfWorkAction } = props;
  const path = happinessImagePath(selfWorkAction.happiness);

  if (path == null) {
    return null;
  }
  return (
    <div>
      <img src={path} alt={selfWorkAction.happiness} className="h-5 w-5" />
    </div>
  );
}
