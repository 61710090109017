import React, { ReactElement, useEffect, useState } from "react";
import { UserResponse } from "../../models/User";
import { CommentDetailResponse } from "../../models/CommentApi";
import CommentBodyItem from "./CommentBodyItem";
import CommentActionItem from "./CommentActionItem";
import getRequest from "../../utils/apiClient";

interface Props {
  comment: CommentDetailResponse;
  currentUser: UserResponse;
  showReplies?: boolean;
}

export default function RootCommentItem(props: Props): ReactElement {
  const { comment, currentUser, showReplies: propShoReplies = false } = props;
  const [showReplies, setShowReplies] = useState(propShoReplies);
  const [replyComments, setReplyComments] = useState<CommentDetailResponse[]>(
    []
  );

  useEffect(() => {
    if (showReplies) {
      loadReplies();
    }
  }, [showReplies]);

  const loadReplies = async (): Promise<void> => {
    const { result } = await getRequest<CommentDetailResponse[]>(
      `/api/comments/${comment.id}/comments/all`
    );
    if (result === undefined) {
      return;
    }
    setReplyComments(result);
  };

  return (
    <div className="border-t pt-2">
      <CommentBodyItem
        comment={comment}
        currentUser={currentUser}
        hasReply={replyComments.length > 0}
        onReplied={(c) => {
          if (showReplies) {
            setReplyComments(replyComments.concat([c]));
          } else {
            setShowReplies(true);
          }
        }}
      />
      {!showReplies && comment.comments_count > 0 && (
        <div
          className="text-primary p-2 text-sm cursor-pointer"
          onClick={() => {
            setShowReplies(true);
          }}
        >
          {`返信を表示: ${comment.comments_count}件`}
        </div>
      )}
      {showReplies &&
        replyComments.map((c, i) => (
          <div key={c.id}>
            <CommentBodyItem
              comment={c}
              currentUser={currentUser}
              isReply
              last={i === replyComments.length - 1}
              onReplied={(c) => {
                if (showReplies) {
                  setReplyComments(replyComments.concat([c]));
                } else {
                  setShowReplies(true);
                }
              }}
            />
          </div>
        ))}
    </div>
  );
}
