import { format, parseISO } from "date-fns";
import ja from "date-fns/locale/ja";
import React, { ReactElement } from "react";
import {
  SelfWorkActionDetailResponse,
  viewBodies,
} from "../../models/SelfWorkActionApi";
import HyperlinkHighlighter from "../shared/HyperlinkHighlighter";
import ImageGrid from "../shared/ImageGrid";
import SelfWorkActionHappiness from "./SelfWorkActionHappiness";
import SelfWorkActionStateLabel from "./SelfWorkActionStateLabel";

interface Props {
  selfWorkAction: SelfWorkActionDetailResponse;
  canPushDetail?: boolean;
  detailPagePath?: string; // canPushDetailがtrueの場合は必須
  searchWords?: string[];
}

export default function SelfWorkActionCardBodyItem(props: Props): ReactElement {
  const {
    selfWorkAction,
    searchWords = [],
    detailPagePath = "",
    canPushDetail = false,
  } = props;
  const imageUrls = selfWorkAction.file_resources.map((fr) => fr.url);
  return (
    <>
      <div
        className={canPushDetail ? "cursor-pointer" : ""}
        onClick={(): void => {
          if (canPushDetail) {
            window.location.href = detailPagePath;
          }
        }}
      >
        <div className="mt-2 flex flex-row justify-between">
          <div className="flex flex-row items-center">
            <div>
              <SelfWorkActionStateLabel selfWorkAction={selfWorkAction} />
            </div>
            <div className="ml-2">
              <SelfWorkActionHappiness selfWorkAction={selfWorkAction} />
            </div>
          </div>

          <div className="flex flex-col items-end">
            <div className="text-xs text-gray-600">
              {`実施日: ${format(
                parseISO(selfWorkAction.planned_date),
                "yyyy年MM月dd日(E)",
                { locale: ja }
              )}`}
            </div>
            {selfWorkAction.continuous_days <= 20 && (
              <div className="text-xs text-gray-600">
                {`連続日数: ${selfWorkAction.continuous_days}日`}
              </div>
            )}
            {selfWorkAction.continuous_days > 21 &&
              selfWorkAction.continuous_days <= 65 && (
                <div className="text-xs text-gray-600 font-bold">
                  {`連続日数: ${selfWorkAction.continuous_days}日`}
                </div>
              )}
            {selfWorkAction.continuous_days > 65 && (
              <div className="text-xs text-orange-500 font-bold">
                {`連続日数: ${selfWorkAction.continuous_days}日`}
              </div>
            )}
            <div className="text-xs text-gray-600">
              {`通算日数: ${selfWorkAction.success_action_count}日`}
            </div>
          </div>
        </div>
        <div className="whitespace-pre-wrap break-words">
          {viewBodies(selfWorkAction).map((body, i) => (
            <div key={i} className="mt-3">
              {body.split("\n").map((b) => (
                <div key={`${String(i)}_${b}`}>
                  <HyperlinkHighlighter
                    searchWords={searchWords}
                    highlightColor="yellow"
                    targetStr={b}
                    highlightClassName="bg-yellow-300"
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      {imageUrls.length > 0 && (
        <div className="flex justify-center mt-2">
          <ImageGrid imageUrls={imageUrls} />
        </div>
      )}
    </>
  );
}
