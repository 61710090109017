import React, { ReactElement, useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CalendarView from "./CalendarView";
import InfiniteList from "../shared/InfiniteList";
import { SelfWorkActionDetailResponse } from "../../models/SelfWorkActionApi";
import { format, startOfMonth, endOfMonth, parseISO } from "date-fns";
import SelfWorkActionCard from "./SelfWorkActionCard";
import { CurrentUserResponse } from "../../models/UserApi";
import Scroll, { animateScroll } from "react-scroll";

interface Props {
  currentUser: CurrentUserResponse;
}

const Element = Scroll.Element;
const scroller = Scroll.scroller;

export default function CalendarLog(props: Props): ReactElement {
  const { currentUser } = props;
  const [currentDate, setCurrentDate] = useState(new Date());
  const [renderCount, setRenderCount] = useState(0);

  const changeMonth = (d: Date): void => {
    setCurrentDate(d);
    setRenderCount(renderCount + 1);
  };

  const scrollTo = (date: Date): void => {
    const option = {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -420, // Scrolls to element + 50 pixels down the page
      isDynamic: true,
    };
    const scrollKey = format(date, "yyyy-MM-dd");
    const a = scroller.get(scrollKey);
    if (scroller.get(scrollKey)) {
      scroller.scrollTo(scrollKey, option);
    } else {
      animateScroll.scrollToBottom(option);
    }
  };
  return (
    <div className="mt-3">
      <CalendarView
        onChangeDate={changeMonth}
        onPressDay={(d) => {
          scrollTo(d);
        }}
      />
      <div>
        <InfiniteList<SelfWorkActionDetailResponse>
          path="/api/self_work_actions"
          q={{
            scope: "mine",
            planned_date_gteq: format(startOfMonth(currentDate), "yyyy-MM-dd"),
            planned_date_lteq: format(endOfMonth(currentDate), "yyyy-MM-dd"),
            actual_date_not_null: "1",
            exists_failed_contents: "1",
            save_state_eq: "2",
            s: ["planned_date desc", "completed_at desc"],
          }}
          renderCount={renderCount}
          renderItem={(swa) => {
            return (
              <Element
                key={swa.id}
                name={format(parseISO(swa.planned_date), "yyyy-MM-dd")}
              >
                <div>
                  <SelfWorkActionCard
                    selfWorkAction={swa}
                    detailPagePath={`/c_user/calendar/self_work_actions/${swa.id}`}
                    currentUser={currentUser}
                    canPushDetail
                    hideUserProfile
                    hideComment
                  />
                </div>
              </Element>
            );
          }}
        />
      </div>
    </div>
  );
}
