import React, { ReactElement, ReactNode, useState } from "react";
import Linkify from "react-linkify";

interface Props {
  text: string;
}

export default function MultiLineText(props: Props): ReactElement {
  const { text } = props;

  const renderTexts = () => {
    if (text) {
      return text.split("\n").map((t, i) => {
        const keyIndex = i;
        return (
          <span key={keyIndex}>
            {t}
            <br />
          </span>
        );
      });
    }
    return "";
  };
  return (
    <span>
      <Linkify properties={{ target: "_blank" }}>{renderTexts()}</Linkify>
    </span>
  );
}
