import React, { ReactElement, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import BaseModal from "./BaseModal";
import BaseModalBody from "./base_modal/BaseModalBody";

interface Props {
  imageUrls: string[];
  width?: number;
  height?: number;
}

export default function ImageGrid(props: Props): ReactElement | null {
  const { imageUrls: images, width, height } = props;
  const [showGallery, setShowGallery] = useState(false);
  const { width: windowWidth } = useWindowDimensions();
  let imageContainerWidth = (windowWidth * 3) / 4;
  if (imageContainerWidth > 400) {
    imageContainerWidth = 400;
  }
  if (width !== undefined) {
    imageContainerWidth = width;
  }

  let imageContainerHeight = imageContainerWidth / 1.618; // 黄金比
  if (height !== undefined) {
    imageContainerHeight = height;
  }

  if (images.length === 0) {
    return null;
  }
  let imageElement: ReactElement | null = null;
  if (images.length === 1) {
    imageElement = (
      <div
        className="shadow"
        style={{ width: imageContainerWidth, height: imageContainerHeight }}
      >
        <img
          src={images[0]}
          className="w-full h-full object-cover rounded"
          alt=""
        />
      </div>
    );
  } else if (images.length === 2) {
    imageElement = (
      <div
        className="flex justify-center shadow"
        style={{ width: imageContainerWidth, height: imageContainerHeight }}
      >
        <div className="w-1/2 h-full">
          <img
            src={images[0]}
            className="w-full h-full object-cover rounded-l"
            alt=""
          />
        </div>
        <div className="w-1/2 h-full">
          <img
            src={images[1]}
            className="w-full h-full object-cover rounded-r"
            alt=""
          />
        </div>
      </div>
    );
  } else if (images.length === 3) {
    imageElement = (
      <div
        className="flex justify-center shadow"
        style={{ width: imageContainerWidth, height: imageContainerHeight }}
      >
        <div className="w-1/2 h-full">
          <img
            src={images[0]}
            className="w-full h-full object-cover rounded-l"
            alt=""
          />
        </div>
        <div className="w-1/2 h-full flex flex-col justify-center">
          <div className="h-1/2 w-full">
            <img
              src={images[1]}
              className="w-full h-full object-cover rounded-tr"
              alt=""
            />
          </div>
          <div className="h-1/2 w-full">
            <img
              src={images[2]}
              className="w-full h-full object-cover rounded-br"
              alt=""
            />
          </div>
        </div>
      </div>
    );
  } else if (images.length === 4) {
    imageElement = (
      <div
        className="flex justify-center shadow"
        style={{ width: imageContainerWidth, height: imageContainerHeight }}
      >
        <div className="w-1/2 h-full">
          <img
            src={images[0]}
            className="w-full h-full object-cover rounded-l"
            alt=""
          />
        </div>
        <div className="w-1/2 h-full flex flex-col justify-center">
          <div className="h-1/2 w-full">
            <img
              src={images[1]}
              className="w-full h-full object-cover rounded-tr"
              alt=""
            />
          </div>
          <div className="h-1/2 w-full flex flex-row justify-center">
            <div className="w-1/2 h-full ">
              <img
                src={images[2]}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <div className="w-1/2 h-full ">
              <img
                src={images[3]}
                className="w-full h-full object-cover rounded-br"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    imageElement = (
      <div
        className="flex justify-center shadow"
        style={{ width: imageContainerWidth, height: imageContainerHeight }}
      >
        <div className="w-1/2 h-full">
          <img
            src={images[0]}
            className="w-full h-full object-cover rounded-l"
            alt=""
          />
        </div>
        <div className="w-1/2 h-full flex flex-col justify-center">
          <div className="h-1/2 w-full flex flex-row justify-center">
            <div className="w-1/2 h-full">
              <img
                src={images[1]}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <div className="w-1/2 h-full ">
              <img
                src={images[2]}
                className="w-full h-full object-cover rounded-tr"
                alt=""
              />
            </div>
          </div>
          <div className="h-1/2 w-full flex flex-row justify-center">
            <div className="w-1/2 h-full ">
              <img
                src={images[3]}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <div className="w-1/2 h-full ">
              <img
                src={images[4]}
                className="w-full h-full object-cover rounded-br"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {showGallery && (
        <BaseModal
          show={showGallery}
          contentLabel="galleryModal"
          onCloseModal={() => setShowGallery(false)}
          onRequestClose={() => setShowGallery(false)}
        >
          <BaseModalBody fixed>
            <ImageGallery
              showPlayButton={false}
              showFullscreenButton={false}
              items={images.map((i) => ({
                original: i,
                originalClass: "h-[52vh]",
              }))}
            />
          </BaseModalBody>
        </BaseModal>
      )}
      <div
        className="cursor-pointer"
        onClick={(e) => {
          setShowGallery(true);
          e.stopPropagation();
        }}
      >
        {imageElement}
      </div>
    </>
  );
}
