import React, { CSSProperties, ReactNode } from "react";
import Modal from "react-modal";
import LoadingImage from "../../assets/images/spin-gray.svg";
import BaseModalBody from "./base_modal/BaseModalBody";
import BaseModalFooter from "./base_modal/BaseModalFooter";

const defaultStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 2000,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -55%)",
    maxHeight: "70%",
    padding: 0,
    border: "none",
    width: "80%",
    overflow: "unset",
  },
};

interface Props {
  show?: boolean;
  title?: string;
  contentLabel: string;
  onCloseModal: () => void;
  children: ReactNode;
  contentStyle?: CSSProperties;
  loading?: boolean;
  onRequestClose?: () => void;
}

function BaseModal(props: Props) {
  const {
    show = false,
    title,
    onCloseModal,
    children,
    contentLabel,
    loading = false,
    contentStyle = {},
    onRequestClose,
  } = props;

  return (
    <Modal
      isOpen={show}
      contentLabel={contentLabel}
      ariaHideApp={false}
      style={Object.assign(defaultStyles, {
        content: Object.assign(defaultStyles.content, contentStyle),
      })}
      onRequestClose={(e) => {
        e.stopPropagation();
        if (onRequestClose !== undefined) {
          onRequestClose();
        }
      }}
    >
      <div className="modal-content">
        {!!title && (
          <div className="flex justify-between items-center px-3 py-1 border-b">
            <h5 className="font-bold text-lg">{title}</h5>
            {onCloseModal && (
              <button
                type="button"
                className="font-bold p-2 text-xl"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  e.stopPropagation();
                  onCloseModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            )}
          </div>
        )}
        <div style={{ position: "relative" }}>
          {children}
          {loading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 1000,
              }}
            >
              <object
                type="image/svg+xml"
                data={LoadingImage}
                width="80"
                height="80"
                title="loading"
                style={{
                  position: "absolute",
                  top: "calc(50% - 65px)",
                  transform: "translate(-50%)",
                  left: "50%",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
// BaseModal.Header = BaseModalHeader;
BaseModal.Body = BaseModalBody;
BaseModal.Footer = BaseModalFooter;
export default BaseModal;
