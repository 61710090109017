import classnames from "classnames";
import React, { ReactElement, ReactNode } from "react";

export interface Props {
  title?: string;
  color?: "primary" | "neutral" | "base-200" | "base-300" | "white";
  opacity?: 75 | 50;
  icon?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  active?: boolean;
  size?: "ex-xs" | "xs" | "sm" | "base";
  border?: boolean;
  full?: boolean;
  rounded?: "default" | "full";
  children?: ReactNode;
  disabledTitle?: string | ReactNode;
  disabled?: boolean;
}

export default function Button(props: Props): ReactElement {
  const {
    title,
    color = "primary",
    opacity = 100,
    icon,
    onClick,
    active = false,
    size = "sm",
    border = true,
    full = false,
    rounded = "default",
    children,
    disabledTitle,
    disabled = false,
  } = props;

  let className = classnames(
    "px-4",
    "text-center",

    "select-none"
  );
  if (size === "ex-xs") {
    className = classnames(className, "py-1");
  } else {
    className = classnames(className, "py-2");
  }

  if (size === "base") {
    className = classnames(className, "text-base");
  } else if (size === "sm") {
    className = classnames(className, "text-sm");
  } else if (size === "xs" || size === "ex-xs") {
    className = classnames(className, "text-xs");
  }
  if (border) {
    className = classnames(className, "border");
  }

  if (active) {
    if (color === "primary") {
      className = classnames(className, "bg-primary", "text-primary-content");
    } else if (color === "base-200") {
      className = classnames(className, "bg-base-200", "text-white");
    } else if (color === "base-300") {
      className = classnames(className, "bg-base-300", "text-white");
    } else if (color === "neutral") {
      className = classnames(className, "bg-neutral", "text-neutral-content");
    } else if (color === "white") {
      className = classnames(className, "bg-white", "text-neutral-content");
    } else {
      className = classnames(className, "bg-white");
    }
  } else {
    if (color === "primary") {
      className = classnames(className, "border-primary", "text-primary");
    } else if (color === "base-200") {
      className = classnames(className, "border-base-200", "text-base-200");
    } else if (color === "base-300") {
      className = classnames(className, "border-base-300", "text-base-300");
    } else if (color === "neutral") {
      className = classnames(className, "border-neutral", "text-neutral");
    } else if (color === "white") {
      className = classnames(className, "bg-white", "text-neutral-content");
    }
  }

  if (opacity === 50) {
    className = classnames(className, "opacity-50");
  } else if (opacity === 75) {
    className = classnames(className, "opacity-75");
  }

  if (full) {
    className = classnames(className, "w-full");
  }

  if (rounded === "default") {
    className = classnames(className, "rounded");
  } else if (rounded === "full") {
    className = classnames(className, "rounded-full");
  }
  if (disabled) {
    className = classnames(className, "opacity-75");
  } else {
    className = classnames(className, "cursor-pointer");
  }

  if (disabled) {
    return (
      <div className={className}>
        {icon}
        {disabledTitle !== undefined ? disabledTitle : title}
      </div>
    );
  }

  return (
    <div
      className={className}
      onClick={(e) => {
        if (onClick !== undefined) {
          onClick(e);
        }
      }}
    >
      {icon}
      {title !== undefined && <span>{title}</span>}
      {title === undefined && children}
    </div>
  );
}
