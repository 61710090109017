import React, { ReactElement, useState } from "react";
import { UserResponse } from "../../models/User";
import { CommentDetailResponse } from "../../models/CommentApi";
import classNames from "classnames";
import ReplyCommentEditModal from "./ReplyCommentEditModal";
import { postRequest } from "../../utils/apiClient";
import { CurrentUserResponse } from "../../models/UserApi";
import ReportModal from "../reports/ReportModal";

interface Props {
  comment: CommentDetailResponse;
  currentUser: UserResponse;
  onReplied: (comment: CommentDetailResponse) => void;
}

function getInitialReactedCount(
  comment: CommentDetailResponse,
  currentUser: CurrentUserResponse | null
): number {
  let tmp = 0;
  if (currentUser != null) {
    const r = comment.reactions.find((r) => r.user.id === currentUser.id);
    if (r !== undefined) {
      tmp = r.count;
    }
  }
  return tmp;
}

export default function CommentActionItem(props: Props): ReactElement {
  const { comment, currentUser, onReplied } = props;
  const initialReactedCount = getInitialReactedCount(comment, currentUser);
  const [reactedCount, setReactedCount] = useState(initialReactedCount);
  const [showModal, setShowModal] = useState(false);

  function countUpGoodReaction(e): void {
    e.preventDefault();
    e.stopPropagation();
    postRequest(`/api/comments/${comment.id}/reactions/count_up`);
    setReactedCount(reactedCount + 1);
  }

  const totalReactedCount =
    comment.reactions.reduce((sum, r) => sum + r.count, 0) +
    reactedCount -
    initialReactedCount;
  return (
    <>
      {showModal && (
        <ReplyCommentEditModal
          replyTargetComment={comment}
          currentUser={currentUser}
          onClose={() => {
            setShowModal(false);
          }}
          onSaved={(editedComment) => {
            setShowModal(false);
            onReplied(editedComment);
            window.Alert.success("送信が完了しました。");
          }}
        />
      )}

      <div className="mt-2">
        <div className="flex flex-row justify-end my-1">
          <div>
            <div className="p-2 text-sm text-gray-600">{`いいね!: ${totalReactedCount}`}</div>
          </div>
        </div>
        <div className="flex flex-row justify-center border-t border-gray-300">
          <div className="w-1/2 text-center">
            <div
              onClick={() => {
                setShowModal(true);
              }}
              className="block py-2 hover:bg-gray-200 text-gray-500 cursor-pointer text-sm"
            >
              <i className="far fa-comment"></i>
              <span className="ml-2">コメント</span>
            </div>
          </div>
          <div className="w-1/2 text-center">
            <div
              onClick={countUpGoodReaction}
              className={classNames(
                "block",
                "py-2",
                "text-sm",
                "hover:bg-gray-200",
                "text-gray-500",
                "cursor-pointer",
                {
                  "text-accessory": totalReactedCount > 0,
                }
              )}
            >
              <i className="fas fa-thumbs-up"></i>
              <span className="ml-2">いいね!</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
