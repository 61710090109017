import React, { ReactElement, useState } from "react";
import { CommentDetailResponse } from "../../models/CommentApi";
import { SelfWorkActionDetailResponse } from "../../models/SelfWorkActionApi";
import { UserResponse } from "../../models/User";
import { CurrentUserResponse } from "../../models/UserApi";
import CommentBodyItem from "../comments/CommentBodyItem";
import SelfWorkActionCardBodyItem from "../self_work_actions/SelfWorkActionCardBodyItem";
import BaseForm, { ajaxSubmit } from "../shared/BaseForm";
import BaseModal from "../shared/BaseModal";
import ButtonWithSubmit from "../shared/ButtonWithSubmit";
import BaseModalBody from "../shared/base_modal/BaseModalBody";
import BaseModalFooter from "../shared/base_modal/BaseModalFooter";
import TextAreaForm from "../shared/forms/TextAreaForm";
import UserProfileImage from "../users/UserProfileImage";

interface Props {
  target: SelfWorkActionDetailResponse | CommentDetailResponse | UserResponse;
  currentUser: CurrentUserResponse;
  onClose: () => void;
}

function isSelfWorkAction(
  target: SelfWorkActionDetailResponse | CommentDetailResponse | UserResponse
): target is SelfWorkActionDetailResponse {
  return "happiness" in target;
}

function isComment(
  target: SelfWorkActionDetailResponse | CommentDetailResponse | UserResponse
): target is CommentDetailResponse {
  return !("happiness" in target) && !("account_name" in target);
}

function isUser(
  target: SelfWorkActionDetailResponse | CommentDetailResponse | UserResponse
): target is UserResponse {
  return "account_name" in target;
}

export default function ReportModal(props: Props): ReactElement {
  const { target, currentUser, onClose } = props;
  const [loading, setLoading] = useState(false);

  const save = async () => {
    const form = document.getElementById("ReportEditForm");
    if (!(form instanceof HTMLFormElement)) {
      return;
    }
    if (form.checkValidity()) {
      setLoading(true);
      const { result } = await ajaxSubmit<object>(form);
      if (result !== undefined) {
        window.Alert.success(
          "通報が完了しました。運営チームが確認し、必要があれば削除します。"
        );
        onClose();
      }
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      const submit = document.getElementById("reportEditFormSubmit");
      if (submit !== null) {
        submit.click();
      }
    }
  };

  let action = "";
  if (isSelfWorkAction(target)) {
    action = `/api/self_work_actions/${target.id}/reports`;
  } else if (isComment(target)) {
    action = `/api/comments/${target.id}/reports`;
  } else if (isUser(target)) {
    action = `/api/users/${target.id}/reports`;
  }

  return (
    <BaseModal
      show={true}
      loading={loading}
      title="通報"
      contentLabel="ReportEditModal"
      onCloseModal={() => onClose()}
    >
      <BaseForm type="ajax" name="ReportEditForm" action={action} method="post">
        <BaseModalBody>
          <>
            {isSelfWorkAction(target) && (
              <div>
                <SelfWorkActionCardBodyItem selfWorkAction={target} />
              </div>
            )}
            {isComment(target) && (
              <div>
                <CommentBodyItem
                  comment={target}
                  currentUser={currentUser}
                  hideActionArea
                  hideMenu
                />
              </div>
            )}
            {isUser(target) && (
              <div className="flex items-center">
                <UserProfileImage user={target} />
                <div className="ml-2">{target.account_name}さん</div>
              </div>
            )}
            <div className="flex-grow pl-2 mt-2">
              <TextAreaForm
                model={{}}
                scope="report"
                columnName="body"
                rows={5}
                hideLabel
                placeholder="通報する理由などがあれば記載ください"
                focusWhenDisplayed
              />
            </div>
          </>
        </BaseModalBody>
        <BaseModalFooter>
          <div className="flex justify-end">
            <ButtonWithSubmit
              title="送信"
              onClick={save}
              submitId="reportEditFormSubmit"
            />
          </div>
        </BaseModalFooter>
      </BaseForm>
    </BaseModal>
  );
}
