import classnames from "classnames";
import React, { ReactElement, ReactNode, useState } from "react";

interface Props {
  contents: Array<{
    title: string;
    onClick: () => void;
  }>;
  menuButtonIcon?: ReactNode;
  menuButtonTitle?: string;
}

export default function MenuButton(props: Props): ReactElement {
  const { contents, menuButtonIcon, menuButtonTitle } = props;
  const [showMenu, setShowMenu] = useState(false);

  const isCustomButton =
    menuButtonIcon !== undefined || menuButtonTitle !== undefined;

  return (
    <div className="relative">
      {showMenu && (
        <>
          <div
            className="fixed inset-0 z-50"
            onClick={() => setShowMenu(false)}
          />
          <div
            className="bg-white text-neutral absolute text-sm rounded shadow border z-50 w-56"
            style={{ top: 25, right: 0 }}
          >
            {contents.map((c, i) => {
              let className = classnames(
                "flex items-center p-3 cursor-pointer hover:bg-base-100"
              );
              if (i !== 0) {
                className = classnames(className, "border-t");
              }
              return (
                <div
                  key={c.title}
                  className={className}
                  onClick={() => {
                    c.onClick();
                    setShowMenu(false);
                  }}
                >
                  {c.title}
                </div>
              );
            })}
          </div>
        </>
      )}
      {menuButtonIcon === undefined && menuButtonTitle === undefined && (
        <div
          onClick={() => setShowMenu(true)}
          className="cursor-pointer text-gray-600"
        >
          <i className="fas fa-ellipsis-h"></i>
        </div>
      )}
      {isCustomButton && (
        <div
          className="px-4 py-2 rounded flex items-center text-center cursor-pointer select-none text-base-300 text-xs"
          onClick={() => setShowMenu(true)}
        >
          <div>{menuButtonIcon}</div>
          <div>{menuButtonTitle}</div>
        </div>
      )}
    </div>
  );
}
