import React, { ReactElement, useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import getRequest from "../../utils/apiClient";

interface Props<T> {
  path: string;
  q: Record<
    string,
    | string
    | number
    | boolean
    | string[]
    | Record<string, string | number | boolean>
  >;
  renderItem: (item: T, index: number) => ReactElement;
  renderCount: number;
  ListEmptyComponent?: ReactElement;
}

export default function InfiniteList<T>(props: Props<T>): ReactElement {
  const { path, q, renderItem, renderCount, ListEmptyComponent } = props;
  const [items, setItems] = useState<T[]>([]);
  // const [page, setPage] = useState(1);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    setItems([]);
    setRefreshing(true);
    setHasMoreItems(true);
  }, [renderCount]);

  useEffect(() => {
    if (refreshing) {
      setRefreshing(false);
    }
  }, [refreshing]);

  const fetchItems = useCallback(
    async (page: number) => {
      if (fetching) {
        return;
      }

      setFetching(true);

      try {
        const { result: additionals } = await getRequest<T[]>(path, {
          q,
          page,
        });
        if (additionals === undefined) {
          return;
        }

        setItems(items.concat(additionals));

        if (additionals.length === 0) {
          setHasMoreItems(false);
        }
      } finally {
        setFetching(false);
      }
    },
    [items, fetching]
  );

  return (
    <>
      {refreshing && (
        <div className="loader" key={0}>
          Loading ...
        </div>
      )}
      {!refreshing && (
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchItems}
          hasMore={hasMoreItems}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
          useWindow={false}
        >
          {items.map((item, i) => renderItem(item, i))}
        </InfiniteScroll>
      )}
      {!refreshing && items.length === 0 && ListEmptyComponent}
    </>
  );
}
