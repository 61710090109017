import React, { ReactElement } from "react";
import Switch from "react-switch";

interface Props {
  title: string;
}

export default function Separator(props: Props): ReactElement {
  const { title } = props;
  return <div className="px-2 py-1 text-sm bg-base-100">{title}</div>;
}
