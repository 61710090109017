import React, { ReactElement } from "react";

interface Props {
  id: string;
  name: string;
  value: string | number;
}

export default function HiddenField(props: Props): ReactElement {
  const { id, name, value } = props;
  return (
    <input
      className="form-control"
      type="hidden"
      value={value}
      name={name}
      id={id}
    />
  );
}
