import React, { ReactElement, useState } from "react";
import { CommentDetailResponse } from "../../models/CommentApi";
import { UserResponse } from "../../models/User";
import BaseForm, { ajaxSubmit } from "../shared/BaseForm";
import BaseModal from "../shared/BaseModal";
import ButtonWithSubmit from "../shared/ButtonWithSubmit";
import BaseModalBody from "../shared/base_modal/BaseModalBody";
import BaseModalFooter from "../shared/base_modal/BaseModalFooter";
import FileResourceImageForm from "../shared/forms/FileResourceImageForm";
import HiddenForm from "../shared/forms/HiddenForm";
import TextAreaForm from "../shared/forms/TextAreaForm";
import UserProfileImage from "../users/UserProfileImage";
import CommentBodyItem from "./CommentBodyItem";

interface Props {
  replyTargetComment?: CommentDetailResponse; // 新規コメント作成時に値を入れる
  comment?: CommentDetailResponse; // コメント編集時に値を入れる
  currentUser: UserResponse;
  onClose: () => void;
  onSaved: (editedComment: CommentDetailResponse) => void;
}

export default function ReplyCommentEditModal(props: Props): ReactElement {
  const { replyTargetComment, comment, currentUser, onClose, onSaved } = props;
  const [loading, setLoading] = useState(false);

  const save = async () => {
    const form = document.getElementById("ReplyCommentEditForm");
    if (!(form instanceof HTMLFormElement)) {
      return;
    }
    if (form.checkValidity()) {
      setLoading(true);
      const { result } = await ajaxSubmit<CommentDetailResponse>(form);
      if (result !== undefined) {
        onSaved(result);
      } else {
        setLoading(false);
      }
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      const submit = document.getElementById("replyCommentEditFormSubmit");
      if (submit !== null) {
        submit.click();
      }
    }
  };

  return (
    <BaseModal
      show={true}
      loading={loading}
      title="コメント"
      contentLabel="ReplyCommentEditModal"
      onCloseModal={() => onClose()}
    >
      <BaseForm
        type="ajax"
        name="ReplyCommentEditForm"
        action={
          comment === undefined && replyTargetComment !== undefined
            ? `/api/comments/${replyTargetComment.id}/comments`
            : comment !== undefined
            ? `/api/comments/${comment.id}`
            : ""
        }
        method={comment === undefined ? "post" : "put"}
      >
        <BaseModalBody>
          <>
            {comment !== undefined && (
              <HiddenForm scope="comment" columnName="id" value={comment.id} />
            )}
            {replyTargetComment !== undefined && (
              <div className="border rounded p-2">
                <CommentBodyItem
                  comment={replyTargetComment}
                  currentUser={currentUser}
                  hideActionArea
                  hideMenu
                />
              </div>
            )}

            <div className="mt-3 flex">
              <div>
                <UserProfileImage user={currentUser} />
              </div>
              <div className="flex-grow pl-2">
                <TextAreaForm
                  model={comment === undefined ? {} : comment}
                  scope="comment"
                  columnName="body"
                  rows={5}
                  hideLabel
                  placeholder="コメント"
                  focusWhenDisplayed
                  required
                />
                <div className="mt-2">
                  <FileResourceImageForm
                    model={
                      comment === undefined ? { file_resources: [] } : comment
                    }
                    scope="comment"
                    multiple
                    currentUser={currentUser}
                    hideLabel
                    hideBottomBorder
                  />
                </div>
              </div>
            </div>
          </>
        </BaseModalBody>
        <BaseModalFooter>
          <div className="flex justify-end">
            <ButtonWithSubmit
              title="送信"
              onClick={save}
              submitId="replyCommentEditFormSubmit"
            />
          </div>
        </BaseModalFooter>
      </BaseForm>
    </BaseModal>
  );
}
