import React, { ReactElement, useEffect, useRef } from "react";

interface Props {
  id: string;
  name: string;
  className?: string;
  value?: string;
  rows: number;
  required?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
  readOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  focusWhenDisplayed?: boolean;
}

export default function TextAreaField(props: Props): ReactElement {
  const {
    className,
    id,
    name,
    value,
    rows,
    required,
    onChange,
    placeholder,
    style,
    readOnly = false,
    focusWhenDisplayed = false,
  } = props;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (focusWhenDisplayed) {
      setTimeout(() => {
        if (textAreaRef?.current !== null) {
          textAreaRef.current.focus();
        }
      }, 300);
    }
  }, []);

  return (
    <textarea
      ref={textAreaRef}
      className={className}
      defaultValue={value}
      name={name}
      id={id}
      rows={rows}
      required={required}
      placeholder={placeholder}
      style={style}
      readOnly={readOnly}
      onChange={(e) => {
        if (onChange !== undefined) {
          onChange(e);
        }
      }}
    />
  );
}
