import React, { ReactElement, useEffect, useState } from "react";
import { HappyAiResponse } from "../../models/HappyAi";
import { postRequest } from "../../utils/apiClient";
import BaseModal from "./BaseModal";

interface Props {
  notRecognizedHappyAis: HappyAiResponse[];
}

export default function AfterCreationMessageModal(props: Props): ReactElement {
  const { notRecognizedHappyAis } = props;
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    recognize();
  }, []);

  const recognize = async (): Promise<void> => {
    await postRequest<object>("/api/c_user/recognize_released_happy_ais");
  };

  return (
    <BaseModal
      show={showModal}
      contentLabel="AfterCreationMessageModal"
      onCloseModal={() => {
        setShowModal(false);
      }}
      onRequestClose={() => {
        setShowModal(false);
      }}
    >
      <BaseModal.Body>
        <div>
          <div className="font-bold text-lg text-center">
            素晴らしい1日でしたね!
          </div>
          <div className="text-center p-2">
            この調子でポジティブな事に目を向けていきましょう。
            <br />
            大切なことは、良かった事に気付くことです。
            <br />
            明日も、是非良かった事を教えてください。
          </div>
          {notRecognizedHappyAis.length > 0 && (
            <div className="mt-4">
              <div className="font-bold text-lg text-center">
                新しいハッピーAIが解放されました!
              </div>
              <div className="flex justify-center p-3">
                {notRecognizedHappyAis.map((ha) => (
                  <div key={ha.id} className="p-2 rounded m-1">
                    <div className="flex justify-center">
                      <img
                        src={ha.icon_url}
                        className="w-10 h-10 object-contain"
                      />
                    </div>
                    <div className="text-center text-sm">{ha.name}</div>
                  </div>
                ))}
              </div>
              <div className="text-center p-2">
                設定の「
                <a href="/c_user/self_work_action_default/happy_ai_edit">
                  ハッピーAI一覧
                </a>
                」から利用したいハッピーAIを選択することで、
                <br />
                ハッピーAIの個性に合わせたコメントを受け取ることができます。
              </div>
            </div>
          )}
        </div>
      </BaseModal.Body>
    </BaseModal>
  );
}
