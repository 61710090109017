import React, { ReactElement } from "react";

interface Props {
  id: string;
  name: string;
  containerClass?: string;
  containerStyle?: React.CSSProperties;
  value?: string;
  className?: string;
  required?: boolean;
  placeholder?: string;
  innerRef?: React.LegacyRef<HTMLTextAreaElement>;
  inputStyle?: React.CSSProperties;
  readOnly?: boolean;
  disabled?: boolean;
  dataValue?: string;
}

export default function TextField(props: Props): ReactElement {
  const {
    containerClass,
    containerStyle,
    id,
    name,
    value,
    className,
    required,
    placeholder,
    inputStyle,
    readOnly = false,
    disabled = false,
    dataValue = "",
  } = props;
  return (
    <div className={containerClass} style={containerStyle}>
      <input
        className={className}
        type="text"
        defaultValue={value}
        name={name}
        id={id}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        style={inputStyle}
        data-value={dataValue}
        readOnly={readOnly}
      />
    </div>
  );
}
