import React, { ReactElement } from "react";
import TextField from "./fields/TextField";
import { fieldId, fieldName, i18nKey } from "../../../utils/formAttributeUtils";
import { i18n } from "../../../utils/i18nUtils";

interface Props {
  model: any;
  scope: string;
  relatedModelName?: string;
  columnName: string;
  label?: string;
  labelColor?: string;
  index?: number;
  className?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  value?: string;
  readOnly?: boolean;
  hideLabel?: boolean;
}

export default function TextForm(props: Props): ReactElement {
  const {
    model,
    scope,
    relatedModelName = "",
    columnName,
    label = "",
    placeholder,
    labelColor,
    index,
    className: propClassName,
    required,
    disabled,
    value,
    readOnly,
    hideLabel = false,
  } = props;

  const viewLabel = (): string => {
    if (label !== "") {
      return label;
    }
    return i18n.t(i18nKey(scope, relatedModelName, columnName));
  };

  const fieldValue = (): string | undefined => {
    if (value !== undefined) {
      return value;
    }
    return model[columnName];
  };

  let className = propClassName;
  if (className === undefined) {
    className = "w-full p-2";
  }

  return (
    <div>
      <div className="flex flex-wrap border-b border-gray-300">
        {!hideLabel && (
          <label
            className="w-full md:w-1/3 px-2 py-3"
            style={{ color: labelColor }}
            htmlFor={fieldId(scope, relatedModelName, columnName, index)}
          >
            {viewLabel()}
          </label>
        )}
        <div className="w-full md:w-2/3 px-2 py-3 flex justify-end">
          <TextField
            id={fieldId(scope, relatedModelName, columnName, index)}
            name={fieldName(scope, relatedModelName, columnName, index)}
            value={fieldValue()}
            className={className}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            readOnly={readOnly}
            containerClass="w-full border"
          />
        </div>
      </div>
    </div>
  );
}
