import { CopyToClipboard } from "react-copy-to-clipboard";
import React, { ReactElement, ReactNode, useState } from "react";
import Button from "./Button";
import BaseModal from "./BaseModal";

export interface Props {
  viewMode?: "button" | "listItem";
  inviteUrl: string;
}

export default function AddFriendButton(props: Props): ReactElement {
  const { viewMode = "button", inviteUrl } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <BaseModal
          title="招待リンク"
          show={true}
          contentLabel="AddFriendModal"
          onCloseModal={() => {
            setShowModal(false);
          }}
        >
          <BaseModal.Body>
            <div className="flex flex-col items-center">
              <div>
                下記招待リンクを共有して、
                <br />
                友達として招待しましょう
              </div>
              <div className="border rounded p-2 max-w-lg break-words">
                {inviteUrl}
              </div>
              <div className="mt-3 py-2">
                <CopyToClipboard
                  text={inviteUrl}
                  onCopy={() => window.alert("招待リンクをコピーしました!")}
                >
                  <Button title="招待リンクをコピー" />
                </CopyToClipboard>
              </div>
            </div>
          </BaseModal.Body>
        </BaseModal>
      )}
      {viewMode === "button" && (
        <Button
          title="友達追加"
          active
          color="white"
          onClick={() => {
            setShowModal(true);
          }}
        />
      )}
      {viewMode === "listItem" && (
        <div className="flex items-center px-4 w-full">
          <div
            className="p-4 border-b border-gray-200 text-left flex-grow cursor-pointer"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <span>友達追加</span>
          </div>
        </div>
      )}
    </>
  );
}
