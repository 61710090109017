import React, { ReactElement, useState } from "react";
import {
  SelfWorkActionDetailResponse,
  viewBodies,
} from "../../models/SelfWorkActionApi";
import { UserResponse } from "../../models/User";
import classNames from "classnames";
import { postRequest } from "../../utils/apiClient";
import SelfWorkActionCommentEditModal from "../comments/SelfWorkActionCommentEditModal";
import { CommentDetailResponse } from "../../models/CommentApi";
import { TwitterShareButton, TwitterIcon } from "react-share";
import ReactionUsersModal from "../reactions/ReactionUsersModal";

interface Props {
  selfWorkAction: SelfWorkActionDetailResponse;
  currentUser: UserResponse;
  canPushDetail?: boolean;
  detailPagePath?: string;
  onReply: (comment: CommentDetailResponse) => void;
}

function getInitialReactedCount(
  selfWorkAction: SelfWorkActionDetailResponse,
  currentUser: UserResponse | null
): number {
  let tmp = 0;
  if (currentUser != null) {
    const r = selfWorkAction.reactions.find(
      (r) => r.user.id === currentUser.id
    );
    if (r !== undefined) {
      tmp = r.count;
    }
  }
  return tmp;
}

export default function SelfWorkActionCardReactionItem(
  props: Props
): ReactElement {
  const {
    selfWorkAction,
    currentUser,
    canPushDetail = false,
    detailPagePath = "",
    onReply,
  } = props;
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showReactUsers, setShowReactUsers] = useState(false);

  const initialReactedCount = getInitialReactedCount(
    selfWorkAction,
    currentUser
  );
  const [reactedCount, setReactedCount] = useState(initialReactedCount);
  const [commentCount, setCommentCount] = useState(
    selfWorkAction.comments.length
  );

  const totalReactedCount =
    selfWorkAction.reactions.reduce((sum, r) => sum + r.count, 0) +
    reactedCount -
    initialReactedCount;

  function countUpGoodReaction(e): void {
    e.preventDefault();
    e.stopPropagation();
    postRequest(
      `/api/self_work_actions/${selfWorkAction.id}/reactions/count_up`
    );
    setReactedCount(reactedCount + 1);
  }

  function countUpComment(): void {
    setCommentCount(commentCount + 1);
  }

  const isMyself = currentUser.id === selfWorkAction.user.id;

  return (
    <>
      {showCommentModal && (
        <SelfWorkActionCommentEditModal
          selfWorkAction={selfWorkAction}
          currentUser={currentUser}
          onClose={() => setShowCommentModal(false)}
          onSaved={(c) => {
            setShowCommentModal(false);
            countUpComment();
            onReply(c);
          }}
        />
      )}
      {showReactUsers && (
        <ReactionUsersModal
          isVisible={showReactUsers}
          onClose={() => {
            setShowReactUsers(false);
          }}
          reactions={selfWorkAction.reactions}
          currentUserReactedCount={reactedCount}
          currentUser={currentUser}
        />
      )}
      <div className="mt-2">
        <div className="flex flex-row justify-end my-1">
          <div
            className={canPushDetail ? "cursor-pointer" : ""}
            onClick={() => {
              if (canPushDetail) {
                window.location.href = detailPagePath;
              }
            }}
          >
            <div className="p-2 text-sm text-gray-600">{`コメント: ${commentCount}件`}</div>
          </div>
          <div>
            <div
              className={classNames("p-2 text-sm text-gray-600", {
                "cursor-pointer": totalReactedCount > 0,
              })}
              onClick={() => {
                if (totalReactedCount > 0) {
                  setShowReactUsers(true);
                }
              }}
            >{`いいね!: ${totalReactedCount}`}</div>
          </div>
        </div>
        <div className="flex flex-row justify-center border-t border-gray-300">
          <div className="w-1/2 text-center">
            <div
              onClick={() => setShowCommentModal(true)}
              className="block py-2 hover:bg-gray-200 text-gray-500 cursor-pointer"
            >
              <i className="far fa-comment"></i>
              <span className="ml-2">コメント</span>
            </div>
          </div>
          {!isMyself && (
            <div className="w-1/2 text-center">
              <div
                onClick={countUpGoodReaction}
                className={classNames(
                  "block",
                  "py-2",
                  "hover:bg-gray-200",
                  "text-gray-500",
                  "cursor-pointer",
                  {
                    "text-accessory": reactedCount > 0,
                  }
                )}
              >
                <i className="fas fa-thumbs-up"></i>
                <span className="ml-2">いいね!</span>
              </div>
            </div>
          )}
          {isMyself && (
            <div className="w-1/2 text-center">
              <TwitterShareButton
                url="https://www.lp.happy-training.jp"
                title={`${viewBodies(selfWorkAction).join("\n")}\n#はぴトレ`}
              >
                <div
                  className={classNames(
                    "block",
                    "py-2",
                    "hover:bg-gray-200",
                    "text-gray-500",
                    "cursor-pointer"
                  )}
                >
                  <i className="fas fa-share"></i>
                  <span className="ml-2">Twitterでシェア</span>
                </div>
              </TwitterShareButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
