import { messaging } from "./firebaseInit";
import { getToken, onMessage } from "firebase/messaging";
import axios from "axios";

function sendTokenToServer(currentToken) {
  console.log("Sending token to server...");
  axios({
    method: "post",
    url: "/api/notification_devices",
    data: {
      token: currentToken,
    },
  })
    .then((results) => {
      console.log("success!");
    })
    .catch((error) => {
      console.log(error.response.data.message);
    });
}

async function requestPermission(): Promise<boolean> {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    return true;
  }
  return false;
}

export async function sendNotificationToken(): Promise<void> {
  const granted = await requestPermission();
  if (granted) {
    const token = await getToken(messaging, {
      vapidKey:
        "BHutqE4V7680TD7pwj9-AMu-wVJQL0xqnmNI9Su7WByHHufz92VOjxtI01OF1TD32hdAbYD8Gyr4RE_X1nsGSP0",
    });
    sendTokenToServer(token);
  }
}
