import React, { ReactElement, useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import InfiniteList from "../shared/InfiniteList";
import { NotificationResponse } from "../../models/Notification";
import { convRelativeTime } from "../../utils/datetimeUtils";
import Button from "../shared/Button";

const selfWorkActionDetailExp = new RegExp("/web/self_work_actions/([0-9]+)");

export default function NotificationList(props): ReactElement {
  return (
    <div className="mt-3">
      <div>
        <InfiniteList<NotificationResponse>
          path="/api/notifications"
          q={{}}
          renderCount={0}
          ListEmptyComponent={<div>通知はありません</div>}
          renderItem={(n) => {
            return (
              <div
                key={n.id}
                onClick={() => {
                  if (n.url != null && n.url !== "") {
                    const resultArr = selfWorkActionDetailExp.exec(n.url);
                    if (resultArr != null) {
                      const selfWorkActionIdStr = resultArr[1];
                      window.location.href = `/c_user/notification/self_work_actions/${selfWorkActionIdStr}`;
                    }
                  }
                }}
                className="border-b cursor-pointer p-2"
              >
                {n.message_title === "" && (
                  <div>
                    <div className="font-bold">{n.message}</div>
                    <div className="text-sm text-base-300">
                      {convRelativeTime(n.created_at)}
                    </div>
                  </div>
                )}
                {n.message_title !== "" && (
                  <div>
                    <div className="font-bold">{n.message_title}</div>
                    <div className="text-sm text-base-300">
                      {convRelativeTime(n.created_at)}
                    </div>
                    <div className="text-sm p-2">{n.message}</div>
                  </div>
                )}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}
