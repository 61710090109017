import React, { ReactElement } from "react";
import { SelfWorkActionDetailResponse } from "../../models/SelfWorkActionApi";

export interface Props {
  selfWorkAction: SelfWorkActionDetailResponse;
}

export default function SelfWorkActionStateLabel(props: Props): ReactElement {
  const { selfWorkAction } = props;

  if (selfWorkAction.state === "opened") {
    return (
      <div className="px-2 py-0 bg-green-600 rounded w-16 text-center text-white text-xs">
        <span className="leading-relaxed">公開中</span>
      </div>
    );
  }

  if (selfWorkAction.state === "friend_opened") {
    return (
      <div className="px-2 py-0 bg-green-600 rounded w-16 text-center text-white text-xs">
        <span className="leading-relaxed">公開(友)</span>
      </div>
    );
  }
  return (
    <div className="px-2 py-0 border border-gray-600 rounded w-16 text-center text-gray-600 text-xs">
      <span className="leading-relaxed">非公開</span>
    </div>
  );
}
