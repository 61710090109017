import React, { ReactElement, useState } from "react";
import { CommentDetailResponse } from "../../models/CommentApi";
import { UserResponse } from "../../models/User";
import { deleteRequest } from "../../utils/apiClient";
import { convRelativeTime } from "../../utils/datetimeUtils";
import ReportModal from "../reports/ReportModal";
import ImageGrid from "../shared/ImageGrid";
import Loading from "../shared/Loading";
import MenuButton from "../shared/MenuButton";
import MultiLineText from "../shared/MultiLineText";
import UserProfileImage from "../users/UserProfileImage";
import CommentActionItem from "./CommentActionItem";
import ReplyCommentEditModal from "./ReplyCommentEditModal";

interface Props {
  comment: CommentDetailResponse;
  currentUser: UserResponse;
  hasReply?: boolean;
  hideActionArea?: boolean;
  hideMenu?: boolean;
  isReply?: boolean;
  last?: boolean;
  onReplied?: (comment: CommentDetailResponse) => void;
}

export default function CommentBodyItem(props: Props): ReactElement | null {
  const {
    comment: propComment,
    currentUser,
    hasReply = false,
    hideActionArea = false,
    hideMenu = false,
    isReply = false,
    last = false,
    onReplied,
  } = props;
  const [showEditModal, setShowEditModal] = useState(false);
  const [comment, setComment] = useState(propComment);
  const [isDeleted, setIsDeleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  const destroy = async (): Promise<void> => {
    if (window.confirm("このコメントを本当に削除してもよろしいですか？")) {
      setLoading(true);
      const { result } = await deleteRequest<{ message: string }>(
        `/api/comments/${comment.id}`
      );
      setLoading(false);
      if (result !== undefined) {
        window.Alert.success("削除が完了しました");
        setIsDeleted(true);
      }
    }
  };

  if (isDeleted) {
    return null;
  }

  const isMyself = currentUser.id === comment.user.id;
  return (
    <div className="relative">
      {showReportModal && (
        <ReportModal
          target={comment}
          currentUser={currentUser}
          onClose={() => {
            setShowReportModal(false);
          }}
        />
      )}
      {showEditModal && (
        <ReplyCommentEditModal
          comment={comment}
          currentUser={currentUser}
          onClose={() => {
            setShowEditModal(false);
          }}
          onSaved={(editedComment) => {
            setShowEditModal(false);
            setComment(editedComment);
            window.Alert.success("編集が完了しました。");
          }}
        />
      )}
      {(hasReply || (isReply && !last)) && (
        <div
          style={{
            width: 10,
            position: "absolute",
            top: 43,
            left: 19,
            bottom: 0,
            borderLeftColor: "gray",
            borderLeftWidth: 2,
          }}
        />
      )}
      <div className="flex relative">
        <div className="flex-none">
          <UserProfileImage user={comment.user} />
        </div>
        <div className="flex-grow">
          <div className="flex justify-between">
            <div className="px-2">
              <div className="text-sm">{comment.user.account_name}</div>
              <div className="text-sm text-base-200">
                {convRelativeTime(comment.created_at)}
              </div>
            </div>
            <div>
              {!hideMenu && (
                <MenuButton
                  contents={
                    isMyself
                      ? [
                          {
                            title: "編集",
                            onClick: () => {
                              setShowEditModal(true);
                            },
                          },
                          {
                            title: "削除",
                            onClick: () => {
                              destroy();
                            },
                          },
                        ]
                      : [
                          {
                            title: "通報",
                            onClick: () => {
                              setShowReportModal(true);
                            },
                          },
                        ]
                  }
                />
              )}
            </div>
          </div>
          <div className="p-2 text-sm">
            <MultiLineText text={comment.body} />
          </div>
          {comment.file_resources.length > 0 && (
            <div className="flex justify-center mt-2">
              <ImageGrid
                imageUrls={comment.file_resources.map((fr) => fr.url)}
              />
            </div>
          )}
          {!hideActionArea && (
            <CommentActionItem
              comment={comment}
              currentUser={currentUser}
              onReplied={(c) => {
                if (onReplied !== undefined) {
                  onReplied(c);
                }
              }}
            />
          )}
        </div>
        {loading && (
          <div className="absolute inset-0">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
}
