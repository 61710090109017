import { differenceInMilliseconds, format, parseISO } from "date-fns";
import ja from "date-fns/locale/ja";

export function convRelativeTime(dateTimeStr: string): string {
  const date = parseISO(dateTimeStr);
  const diff = differenceInMilliseconds(new Date(), date);
  const oneMin = 1000 * 60;
  if (diff < oneMin) {
    return "1分以内";
  }
  const oneHour = oneMin * 60;
  if (diff < oneHour) {
    const min = Math.floor(diff / oneMin);
    return `${min}分前`;
  }
  const oneDay = oneHour * 24;
  if (diff < oneDay) {
    const hour = Math.floor(diff / oneHour);
    return `${hour}時間前`;
  }
  const oneWeek = oneDay * 7;
  if (diff < oneWeek) {
    const day = Math.floor(diff / oneDay);
    return `${day}日前`;
  }
  return format(date, "yyyy年MM月dd日", { locale: ja });
}
