import React, { ReactElement, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { SelfWorkActionEditType } from "../../models/SelfWorkAction";
import HiddenField from "../shared/forms/fields/HiddenField";
import { fieldId, fieldName } from "../../utils/formAttributeUtils";

const options = [
  { key: "terrible", image: require("../../assets/images/terrible.png") },
  { key: "very_bad", image: require("../../assets/images/very_bad.png") },
  { key: "bad", image: require("../../assets/images/bad.png") },
  { key: "normal", image: require("../../assets/images/average.png") },
  { key: "good", image: require("../../assets/images/good.png") },
  { key: "very_good", image: require("../../assets/images/very_good.png") },
  { key: "excellent", image: require("../../assets/images/excellent.png") },
];

interface Props {
  selfWorkAction: SelfWorkActionEditType;
}

export default function HappinessForm(props: Props): ReactElement {
  const { selfWorkAction } = props;
  const [happiness, setHappiness] = useState(selfWorkAction.happiness);
  const happinessIndex = options.findIndex((o) => o.key === happiness);

  const sliderValueChange = (value: number): void => {
    const index = Math.floor((value * 7) / 100);
    const option = options[index];
    setHappiness(option.key);
  };

  return (
    <div>
      <HiddenField
        id={fieldId("self_work_action", "", "happiness")}
        name={fieldName("self_work_action", "", "happiness")}
        value={happiness}
      />
      <div className="flex flex-wrap border-b border-gray-300">
        <div className="w-full md:w-1/3 px-2 py-3">
          今日はどんな1日でしたか？
        </div>
        <div className="w-full md:w-2/3 px-2 py-3 flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="text-gray-600 text-sm">Bad..</div>
            <div className="text-gray-600 text-sm">Good!!</div>
          </div>
          <div className="flex flex-row justify-around">
            {options.map((o) => (
              <div
                key={o.key}
                className={
                  o.key === happiness
                    ? "border rounded border-orange-500 cursor-pointer"
                    : "border border-white cursor-pointer"
                }
                onClick={() => setHappiness(o.key)}
              >
                <img src={o.image} className="w-12" />
              </div>
            ))}
          </div>
          <div className="mt-2">
            <Slider
              onChange={sliderValueChange}
              min={0}
              max={99}
              value={(happinessIndex * 99) / 6}
              trackStyle={{ backgroundColor: "orange" }}
              handleStyle={{
                borderColor: "orange",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
