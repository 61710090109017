import React, { ReactElement, useState } from "react";
import { SelfWorkActionDetailResponse } from "../../models/SelfWorkActionApi";
import UserProfileImage from "../users/UserProfileImage";
import { convRelativeTime } from "../../utils/datetimeUtils";
import { UserResponse } from "../../models/User";
import MenuButton from "../shared/MenuButton";
import ReportModal from "../reports/ReportModal";
import BlockUserEditModal from "../block_users/BlockUserEditModal";
import BlockSelfWorkActionEditModal from "../block_self_work_actions/BlockSelfWorkActionEditModal";
import { deleteRequest } from "../../utils/apiClient";
import BaseForm from "../shared/BaseForm";
import ButtonWithSubmit from "../shared/ButtonWithSubmit";

interface Props {
  selfWorkAction: SelfWorkActionDetailResponse;
  currentUser: UserResponse;
  hideMenu?: boolean;
  editPagePath?: string;
  afterBlocked?: () => void;
}

export default function SelfWorkActionCardHeaderItem(
  props: Props
): ReactElement {
  const {
    selfWorkAction,
    currentUser,
    editPagePath,
    hideMenu = false,
    afterBlocked,
  } = props;
  const [showSelfWorkActionReportModal, setShowSelfWorkActionReportModal] =
    useState(false);
  const [showUserReportModal, setShowUserReportModal] = useState(false);
  const [showBlockUserEditModal, setShowBlockUserEditModal] = useState(false);
  const [
    showBlockSelfWorkActionEditModal,
    setShowBlockSelfWorkActionEditModal,
  ] = useState(false);

  const deleteSelfWorkAction = async (): Promise<void> => {
    if (window.confirm("この投稿を本当に削除してもよろしいですか？")) {
      const submit = document.getElementById("deleteSelfWorkActionSubmit");
      if (submit !== null) {
        submit.click();
      }
    }
  };

  return (
    <>
      <BaseForm
        name="deleteSelfWorkActionForm"
        action={`/c_user/self_work_actions/${selfWorkAction.id}`}
        method="delete"
      >
        <input
          id="deleteSelfWorkActionSubmit"
          type="submit"
          className="p-0 opacity-0 border-0"
        />
      </BaseForm>
      {showSelfWorkActionReportModal && (
        <ReportModal
          target={selfWorkAction}
          currentUser={currentUser}
          onClose={() => {
            setShowSelfWorkActionReportModal(false);
          }}
        />
      )}
      {showUserReportModal && (
        <ReportModal
          target={selfWorkAction.user}
          currentUser={currentUser}
          onClose={() => {
            setShowUserReportModal(false);
          }}
        />
      )}
      {showBlockUserEditModal && (
        <BlockUserEditModal
          user={selfWorkAction.user}
          onClose={() => {
            setShowBlockUserEditModal(false);
          }}
        />
      )}
      {showBlockSelfWorkActionEditModal && (
        <BlockSelfWorkActionEditModal
          selfWorkAction={selfWorkAction}
          onClose={() => {
            setShowBlockSelfWorkActionEditModal(false);
          }}
          afterSaved={() => {
            setShowBlockSelfWorkActionEditModal(false);
            if (afterBlocked !== undefined) {
              afterBlocked();
            }
          }}
        />
      )}
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center">
          <div className="flex-none">
            <UserProfileImage user={selfWorkAction.user} />
          </div>
          <div className="ml-2 flex-shrink">
            <div className="">{selfWorkAction.user.account_name}さん</div>
            <div className="text-gray-600 text-xs">
              {convRelativeTime(selfWorkAction.created_at)}
            </div>
          </div>
        </div>
        <div className="p-2">
          {!hideMenu && (
            <MenuButton
              contents={
                selfWorkAction.user.id === currentUser.id
                  ? [
                      {
                        title: "編集",
                        onClick: () => {
                          if (editPagePath !== undefined) {
                            window.location.href = editPagePath;
                          }
                        },
                      },
                      {
                        title: "削除",
                        onClick: () => {
                          deleteSelfWorkAction();
                        },
                      },
                    ]
                  : [
                      {
                        title: "この投稿を非表示",
                        onClick: () => {
                          setShowBlockSelfWorkActionEditModal(true);
                        },
                      },
                      {
                        title: "この投稿を通報",
                        onClick: () => {
                          setShowSelfWorkActionReportModal(true);
                        },
                      },
                      {
                        title: `${selfWorkAction.user.account_name}さんをブロック`,
                        onClick: () => {
                          setShowBlockUserEditModal(true);
                        },
                      },
                      {
                        title: `${selfWorkAction.user.account_name}さんを通報`,
                        onClick: () => {
                          setShowUserReportModal(true);
                        },
                      },
                    ]
              }
            />
          )}
        </div>
      </div>
    </>
  );
}
