import LinkifyIt from "linkify-it";
import React, { ReactElement } from "react";
import Highlighter from "react-highlight-words";

function removeEndNewLineCode(str: string): string {
  return str.replace(/\r?\n$/, "");
}

const linkify = new LinkifyIt();

interface Props {
  searchWords: string[];
  highlightColor: string;
  targetStr?: string;
  highlightClassName: string;
}

export default function HyperlinkHighlighter(
  props: Props
): ReactElement | null {
  const { targetStr, highlightClassName, searchWords, highlightColor } = props;
  if (targetStr === undefined) {
    return null;
  }
  const linkifyArr = linkify.match(targetStr);
  if (linkifyArr == null || linkifyArr.length === 0) {
    // urlが含まれていない文字列
    return (
      <Highlighter
        key="first"
        highlightClassName={highlightClassName}
        searchWords={searchWords}
        textToHighlight={targetStr}
      />
    );
  }
  const elements: ReactElement[] = [];
  let _lastIndex = 0;
  linkifyArr.forEach(({ index, lastIndex, text, url }) => {
    const nonLinkedText = targetStr.substring(_lastIndex, index);
    if (nonLinkedText !== "") {
      const modifieredNonLinkedText = removeEndNewLineCode(nonLinkedText);
      if (modifieredNonLinkedText !== "") {
        // urlの前の部分
        elements.push(
          <Highlighter
            key={`first_${url}_${index}`}
            highlightClassName={highlightClassName}
            searchWords={searchWords}
            textToHighlight={modifieredNonLinkedText}
          />
        );
      }
    }
    _lastIndex = lastIndex;
    // url部分
    elements.push(
      <a
        key={`${url}_${index}`}
        href="#"
        className="text-blue-500"
        rel="noreferrer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          window.open(url, "_blank");
        }}
      >
        {url}
      </a>
    );
  });

  // urlの後ろの部分
  const endStr = targetStr.substring(_lastIndex, targetStr.length);
  endStr !== "" &&
    elements.push(
      <Highlighter
        key="end"
        highlightStyle={{ backgroundColor: highlightColor }}
        searchWords={searchWords}
        textToHighlight={removeEndNewLineCode(endStr)}
      />
    );
  return <React.Fragment>{elements}</React.Fragment>;
}
