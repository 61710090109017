import React, { ReactElement, useEffect, useState } from "react";
import {
  Calendar,
  dateFnsLocalizer,
  DateFormat,
  Event,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { startOfMonth, endOfMonth, parseISO } from "date-fns";
import ja from "date-fns/locale/ja";
import CalendarViewHappinessIcon from "./CalendarViewHappinessIcon";
import getRequest from "../../utils/apiClient";
import { SelfWorkActionMarkedIconType } from "../../models/SelfWorkActionApi";
import Loading from "../shared/Loading";

const locales = {
  ja: ja,
};

const localize = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

interface Props {
  onChangeDate: (date: Date) => void;
  onPressDay: (date: Date) => void;
}

export default function CalendarView(props: Props): ReactElement {
  const { onChangeDate, onPressDay } = props;
  const [loading, setLoading] = useState(false);
  const [markedIcons, setMarkedIcons] = useState<
    SelfWorkActionMarkedIconType[]
  >([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    loadMarkedDates();
    onChangeDate(currentDate);
  }, [currentDate]);

  async function loadMarkedDates(): Promise<void> {
    setLoading(true);
    const { result } = await getRequest<SelfWorkActionMarkedIconType[]>(
      "/api/self_work_actions/marked_icon",
      {
        q: {
          scope: "mine",
          planned_date_gteq: format(startOfMonth(currentDate), "yyyy-MM-dd"),
          planned_date_lteq: format(endOfMonth(currentDate), "yyyy-MM-dd"),
          actual_date_not_null: "1",
          exists_failed_contents: "1",
          save_state_not_eq: "temp",
        },
      }
    );
    if (result !== undefined) {
      setMarkedIcons(result);
    }
    setLoading(false);
  }

  return (
    <div className="relative">
      <Calendar
        culture="ja"
        localizer={localize}
        events={markedIcons}
        views={["month"]}
        messages={{
          previous: "前月",
          next: "翌月",
          today: "今日",
        }}
        onSelectEvent={(e: any) => {
          if (e.start !== undefined) {
            onPressDay(parseISO(e.start));
          }
        }}
        onNavigate={(date) => {
          setCurrentDate(date);
        }}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        components={{
          event: CalendarViewHappinessIcon,
        }}
        eventPropGetter={() => ({ style: { backgroundColor: "white" } })}
        formats={{
          weekdayFormat: (date, culture, localizer) => {
            if (localizer !== undefined) {
              return localizer.format(date, "EE", culture);
            }
            return "-";
          },
          monthHeaderFormat: (date, culture, localizer) => {
            if (localizer !== undefined) {
              return localizer.format(date, "yyyy年MM月", culture);
            }
            return "-";
          },
        }}
      />
      {loading && (
        <div className="absolute inset-0">
          <div className="flex justify-center items-center h-full">
            <Loading />
          </div>
        </div>
      )}
    </div>
  );
}
