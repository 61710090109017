import React, { ReactElement } from "react";

interface Props {
  id: string;
  name: string;
  containerClass?: string;
  containerStyle?: React.CSSProperties;
  options: Array<{ key: string; value: string }>;
  selectedKey?: string | number;
  option?: { include_blank?: string };
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  required?: boolean;
}

export default function SelectField(props: Props): ReactElement {
  const {
    containerClass,
    containerStyle,
    id,
    name,
    options,
    selectedKey,
    option = {},
    onChange,
    disabled,
    required,
  } = props;
  return (
    <div className={containerClass} style={containerStyle}>
      <select
        name={name}
        id={id}
        defaultValue={String(selectedKey)}
        onChange={(e) => {
          if (onChange !== undefined) {
            onChange(e);
          }
        }}
        disabled={disabled}
        required={required}
        className="text-right"
      >
        {option.include_blank !== undefined && (
          <option key="include_blank" label={option.include_blank} value="" />
        )}
        {options.map(({ key, value }) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
}
