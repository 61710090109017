import React, { ReactElement, useState } from "react";
import { happinessImagePath } from "../../models/SelfWorkAction";

interface Props {
  event: any;
}

export default function CalendarViewHappinessIcon(
  props: Props
): ReactElement | null {
  const { event } = props;

  const happiness = event?.happinesses[0] as string | undefined;
  if (happiness === undefined) {
    return null;
  }
  const icon = happinessImagePath(happiness);

  if (icon === null) {
    return null;
  }

  return (
    <div className="flex justify-center">
      <img src={icon} className="h-8 w-8" />
    </div>
  );
}
