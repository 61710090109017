import React, { ReactElement, useState } from "react";
import { SelfWorkActionDetailResponse } from "../../models/SelfWorkActionApi";
import SelfWorkActionCardBodyItem from "../self_work_actions/SelfWorkActionCardBodyItem";
import BaseForm, { ajaxSubmit } from "../shared/BaseForm";
import BaseModal from "../shared/BaseModal";
import ButtonWithSubmit from "../shared/ButtonWithSubmit";
import BaseModalBody from "../shared/base_modal/BaseModalBody";
import BaseModalFooter from "../shared/base_modal/BaseModalFooter";
import HiddenForm from "../shared/forms/HiddenForm";
import TextAreaForm from "../shared/forms/TextAreaForm";

interface Props {
  selfWorkAction: SelfWorkActionDetailResponse;
  onClose: () => void;
  afterSaved: () => void;
}

export default function BlockSelfWorkActionEditModal(
  props: Props
): ReactElement {
  const { selfWorkAction, onClose, afterSaved } = props;
  const [loading, setLoading] = useState(false);

  const save = async () => {
    const form = document.getElementById("BlockSelfWorkActionEditForm");
    if (!(form instanceof HTMLFormElement)) {
      return;
    }
    if (form.checkValidity()) {
      setLoading(true);
      const { result } = await ajaxSubmit<object>(form);
      if (result !== undefined) {
        afterSaved();
      }
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      const submit = document.getElementById(
        "blockSelfWorkActionEditFormSubmit"
      );
      if (submit !== null) {
        submit.click();
      }
    }
  };

  return (
    <BaseModal
      loading={loading}
      show={true}
      title="投稿を非表示"
      contentLabel="BlockSelfWorkActionEditModal"
      onCloseModal={() => onClose()}
    >
      <BaseForm
        type="ajax"
        name="BlockSelfWorkActionEditForm"
        action="/api/block_self_work_actions"
        method="post"
      >
        <BaseModalBody>
          <>
            <div>
              <SelfWorkActionCardBodyItem selfWorkAction={selfWorkAction} />
            </div>
            <div className="flex-grow pl-2 mt-2">
              <HiddenForm
                scope="block_self_work_action"
                columnName="block_self_work_action_id"
                value={selfWorkAction.id}
              />
              <TextAreaForm
                model={{}}
                scope="block_self_work_action"
                columnName="body"
                rows={5}
                hideLabel
                placeholder="非表示にする理由などがあれば記載ください"
                focusWhenDisplayed
              />
            </div>
          </>
        </BaseModalBody>
        <BaseModalFooter>
          <div className="flex justify-end">
            <ButtonWithSubmit
              title="送信"
              onClick={save}
              submitId="blockSelfWorkActionEditFormSubmit"
            />
          </div>
        </BaseModalFooter>
      </BaseForm>
    </BaseModal>
  );
}
