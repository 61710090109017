import React, { ReactElement } from "react";
import TextAreaField from "./fields/TextAreaField";
import { fieldId, fieldName, i18nKey } from "../../../utils/formAttributeUtils";
import { i18n } from "../../../utils/i18nUtils";

interface Props {
  scope: string;
  model?: any;
  relatedModelName?: string;
  columnName: string;
  label?: string;
  subLabel?: string;
  rows: number;
  index?: number;
  labelColor?: string;
  required?: boolean;
  hideLabel?: boolean;
  placeholder?: string;
  focusWhenDisplayed?: boolean;
}

export default function TextAreaForm(props: Props): ReactElement {
  const {
    scope,
    relatedModelName = "",
    columnName,
    label = "",
    rows,
    index,
    labelColor,
    model,
    required,
    hideLabel = false,
    placeholder,
    subLabel,
    focusWhenDisplayed,
  } = props;

  const viewLabel = (): string => {
    if (label !== "") {
      return label;
    }
    return i18n.t(i18nKey(scope, relatedModelName, columnName));
  };

  return (
    <div>
      <div className="flex flex-wrap border-b border-gray-300">
        {!hideLabel && (
          <label
            className="w-full md:w-1/3 px-2 py-3"
            htmlFor={fieldId(scope, relatedModelName, columnName, index)}
            style={{ color: labelColor }}
          >
            {subLabel !== undefined && <div className="">{subLabel}</div>}
            {viewLabel()}
          </label>
        )}

        <div
          className={`w-full ${hideLabel ? "" : "md:w-2/3"} flex justify-end`}
        >
          <TextAreaField
            id={fieldId(scope, relatedModelName, columnName, index)}
            name={fieldName(scope, relatedModelName, columnName, index)}
            value={model[columnName]}
            rows={rows}
            required={required}
            placeholder={placeholder}
            focusWhenDisplayed={focusWhenDisplayed}
            className="w-full border px-2 py-3"
          />
        </div>
      </div>
    </div>
  );
}
