import React, { ReactElement, useState } from "react";
import Button, { Props as ButtonProps } from "./Button";
import ScreenLoading from "./ScreenLoading";

interface Props extends ButtonProps {
  submitId: string;
  title: string;
  submittingTitle: string;
  color?: "primary" | "neutral" | "base-200" | "base-300" | "white";
  opacity?: 75 | 50;
  active?: boolean;
  size?: "ex-xs" | "xs" | "sm" | "base";
  border?: boolean;
  full?: boolean;
  rounded?: "default" | "full";
}

export default function SubmitButtonFromOutsideComponent(
  props: Props
): ReactElement {
  const { submitId, submittingTitle, ...otherProps } = props;
  const [loading, setLoading] = useState(false);

  async function submitHandler() {
    const submit = document.getElementById(submitId);
    if (submit !== null) {
      setLoading(true);
      submit.click();
    }
  }

  return (
    <>
      <Button
        {...otherProps}
        disabled={loading}
        disabledTitle={submittingTitle}
        onClick={() => submitHandler()}
      />
      <ScreenLoading show={loading} />
    </>
  );
}
