import React, { ReactElement, useState } from "react";
import { UserResponse } from "../../models/User";
import BaseForm from "../shared/BaseForm";
import BaseModal from "../shared/BaseModal";
import BaseModalBody from "../shared/base_modal/BaseModalBody";
import BaseModalFooter from "../shared/base_modal/BaseModalFooter";
import HiddenForm from "../shared/forms/HiddenForm";
import TextAreaForm from "../shared/forms/TextAreaForm";
import UserProfileImage from "../users/UserProfileImage";

interface Props {
  user: UserResponse;
  onClose: () => void;
}

export default function BlockUserEditModal(props: Props): ReactElement {
  const { user, onClose } = props;
  const [loading, setLoading] = useState(false);

  return (
    <BaseModal
      show={true}
      loading={loading}
      title="ユーザーブロック"
      contentLabel="BlockUserEditModal"
      onCloseModal={() => onClose()}
    >
      <BaseForm
        name="BlockUserEditForm"
        action="/c_user/block_users"
        method="post"
      >
        <BaseModalBody>
          <>
            <div className="flex items-center">
              <UserProfileImage user={user} />
              <div className="ml-2">{user.account_name}さん</div>
            </div>
            <div className="flex-grow pl-2 mt-2">
              <HiddenForm
                scope="block_user"
                columnName="block_user_id"
                value={user.id}
              />
              <TextAreaForm
                model={{}}
                scope="block_user"
                columnName="body"
                rows={5}
                hideLabel
                placeholder="ブロックする理由などがあれば記載ください"
                focusWhenDisplayed
              />
            </div>
          </>
        </BaseModalBody>
        <BaseModalFooter>
          <div className="flex justify-end">
            <input
              type="submit"
              value="送信"
              className="bg-primary text-white px-3 py-2 rounded cursor-pointer"
              data-disable-with="登録中.."
              // disabled
            />
          </div>
        </BaseModalFooter>
      </BaseForm>
    </BaseModal>
  );
}
