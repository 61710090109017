import React, { ReactElement } from "react";
import HiddenField from "./fields/HiddenField";
import { fieldId, fieldName } from "../../../utils/formAttributeUtils";

interface Props {
  scope: string;
  relatedModelName?: string;
  columnName: string;
  value: string | number;
  index?: number;
}

export default function HiddenForm(props: Props): ReactElement {
  const { scope, relatedModelName = "", columnName, value, index = -1 } = props;
  return (
    <HiddenField
      id={fieldId(scope, relatedModelName, columnName, index)}
      name={fieldName(scope, relatedModelName, columnName, index)}
      value={value}
    />
  );
}
