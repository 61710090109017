import React, { ReactElement, useState } from "react";
import BaseForm from "../shared/BaseForm";
import {
  NotificationSettingResponse,
  recieveGoodReactionDefaultValue,
  remindActionDefaultValue,
} from "../../models/NotificationSetting";
import Separator from "../shared/Separator";
import SelectForm from "../shared/forms/SelectForm";
import SwitchForm from "../shared/forms/SwitchForm";
import HiddenForm from "../shared/forms/HiddenForm";

export interface Props {
  notificationSettings: NotificationSettingResponse[];
}

const timeOptions = (): Array<{ key: string; value: string }> => {
  const arr: Array<{ key: string; value: string }> = [];
  for (let hours = 0; hours < 24; hours += 1) {
    for (let minutes = 0; minutes < 60; minutes += 10) {
      const timeStr = `${("0" + String(hours)).slice(-2)}:${(
        "0" + String(minutes)
      ).slice(-2)}`;
      arr.push({ key: timeStr, value: timeStr });
    }
  }
  return arr;
};

export default function NotificationSettingEditForm(
  props: Props
): ReactElement {
  const { notificationSettings } = props;
  let initialRecieveGoodReactionSetting = notificationSettings.find(
    (s) => s.role === "recieve_good_reaction"
  );
  if (initialRecieveGoodReactionSetting === undefined) {
    initialRecieveGoodReactionSetting = recieveGoodReactionDefaultValue();
  }

  let initialRemindActionSetting = notificationSettings.find(
    (s) => s.role === "remind_action"
  );
  if (initialRemindActionSetting === undefined) {
    initialRemindActionSetting = remindActionDefaultValue();
  }
  const [recieveGoodReactionSetting, setRecieveGoodReactionSetting] = useState(
    initialRecieveGoodReactionSetting
  );
  const [remindActionSetting, setRemindActionSetting] = useState(
    initialRemindActionSetting
  );

  return (
    <BaseForm
      action="/c_user/notification_settings_update"
      name="NotificationSettingEditForm"
      method="put"
    >
      <div className="bg-gray-200 p-2 shadow-b">
        <div className="max-w-screen-lg flex justify-end px-2 mx-auto">
          <input
            type="submit"
            value="更新"
            className="bg-primary text-white px-3 py-2 rounded cursor-pointer"
            data-disable-with="更新中.."
            // disabled
          />
        </div>
      </div>
      <div className="max-w-screen-lg px-2 mx-auto mt-4">
        <Separator title="いいね!の通知" />
        {recieveGoodReactionSetting.id !== undefined && (
          <HiddenForm
            scope="user"
            relatedModelName="notification_settings"
            index={0}
            columnName="id"
            value={recieveGoodReactionSetting.id}
          />
        )}
        <HiddenForm
          scope="user"
          relatedModelName="notification_settings"
          index={0}
          columnName="role"
          value="recieve_good_reaction"
        />
        <SwitchForm
          model={recieveGoodReactionSetting}
          scope="user"
          relatedModelName="notification_settings"
          index={0}
          columnName="state"
          activeValue="activated"
          disableValue="disabled"
          noBorder
        />
        <Separator title="リマインド通知" />
        {remindActionSetting.id !== undefined && (
          <HiddenForm
            scope="user"
            relatedModelName="notification_settings"
            index={1}
            columnName="id"
            value={remindActionSetting.id}
          />
        )}
        <HiddenForm
          scope="user"
          relatedModelName="notification_settings"
          index={1}
          columnName="role"
          value="remind_action"
        />
        <SwitchForm
          model={remindActionSetting}
          scope="user"
          relatedModelName="notification_settings"
          index={1}
          columnName="state"
          activeValue="scheduled"
          disableValue="disabled"
          onChange={(v) =>
            setRemindActionSetting(
              Object.assign({}, remindActionSetting, {
                state: v ? "scheduled" : "disabled",
              })
            )
          }
          noBorder={remindActionSetting.state === "scheduled"}
        />
        {remindActionSetting.state === "scheduled" && (
          <>
            <Separator title="リマインド通知曜日" />
            <SwitchForm
              model={remindActionSetting}
              scope="user"
              relatedModelName="notification_settings"
              index={1}
              columnName="notify_monday_flg"
              activeValue="1"
              disableValue="0"
              label="月曜日"
            />
            <SwitchForm
              model={remindActionSetting}
              scope="user"
              relatedModelName="notification_settings"
              index={1}
              columnName="notify_tuesday_flg"
              activeValue="1"
              disableValue="0"
              label="火曜日"
            />
            <SwitchForm
              model={remindActionSetting}
              scope="user"
              relatedModelName="notification_settings"
              index={1}
              columnName="notify_wednesday_flg"
              activeValue="1"
              disableValue="0"
              label="水曜日"
            />
            <SwitchForm
              model={remindActionSetting}
              scope="user"
              relatedModelName="notification_settings"
              index={1}
              columnName="notify_thursday_flg"
              activeValue="1"
              disableValue="0"
              label="木曜日"
            />
            <SwitchForm
              model={remindActionSetting}
              scope="user"
              relatedModelName="notification_settings"
              index={1}
              columnName="notify_friday_flg"
              activeValue="1"
              disableValue="0"
              label="金曜日"
            />
            <SwitchForm
              model={remindActionSetting}
              scope="user"
              relatedModelName="notification_settings"
              index={1}
              columnName="notify_saturday_flg"
              activeValue="1"
              disableValue="0"
              label="土曜日"
            />
            <SwitchForm
              model={remindActionSetting}
              scope="user"
              relatedModelName="notification_settings"
              index={1}
              columnName="notify_sunday_flg"
              activeValue="1"
              disableValue="0"
              label="日曜日"
            />
            <div>
              <SelectForm
                label="リマインド通知時間"
                model={remindActionSetting}
                scope="user"
                relatedModelName="notification_settings"
                index={1}
                columnName="notification_time"
                options={timeOptions()}
              />
            </div>
          </>
        )}
      </div>
      <div className="w-full flex justify-center mt-10">
        <input
          type="submit"
          value="更新"
          className="bg-primary text-white px-3 py-2 rounded cursor-pointer w-1/2"
          data-disable-with="更新中.."
          // disabled
        />
      </div>
    </BaseForm>
  );
}
