import classnames from "classnames";
import React, { ReactElement, ReactNode, useRef, useState } from "react";
import { uploadToAws } from "../../../utils/imageUtils";
import Button from "../Button";
import ImageGrid from "../ImageGrid";
import Loading from "../Loading";
import HiddenForm from "./HiddenForm";

interface Props {
  model: any;
  scope: string;
  columnName: string;
  defaultUrl: string | null;
  relatedModelName?: string;
  hideLabel?: boolean;
  hideBottomBorder?: boolean;
  previewComponent?: (imageUrl: string) => ReactNode;
}

export default function ImageForm(props: Props): ReactElement {
  const {
    model,
    scope,
    columnName,
    defaultUrl,
    relatedModelName,
    hideLabel = false,
    hideBottomBorder = false,
    previewComponent,
  } = props;
  const [uploading, setUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | undefined | null>(
    defaultUrl
  );
  const [imageKey, setImageKey] = useState<string | undefined | null>();
  const inputRef = useRef<HTMLInputElement>(null);

  const upload = async (file: File) => {
    if (uploading) {
      return;
    }
    setUploading(true);
    const res = await uploadToAws(file);
    if (res.result !== undefined) {
      setImageUrl(res.result.tempUrl);
      setImageKey(res.result.key);
    }
    setUploading(false);
  };

  let formContainerClassName = "flex flex-wrap";
  if (!hideBottomBorder) {
    formContainerClassName = classnames(
      formContainerClassName,
      "border-b",
      "border-gray-300"
    );
  }

  return (
    <>
      {imageKey !== undefined && imageKey !== null && (
        <HiddenForm
          scope={scope}
          relatedModelName={relatedModelName}
          index={0}
          columnName={columnName}
          value={imageKey}
        />
      )}
      <div>
        <div className={formContainerClassName}>
          {!hideLabel && (
            <label className="w-full md:w-1/3 px-2 py-3">画像</label>
          )}
          {uploading && (
            <div className="w-full">
              <Loading />
            </div>
          )}
          {!uploading && imageUrl !== undefined && imageUrl !== null && (
            <>
              {previewComponent === undefined && (
                <div className="flex justify-center w-full">
                  <ImageGrid imageUrls={[imageUrl]} />
                </div>
              )}
              {previewComponent !== undefined && previewComponent(imageUrl)}
            </>
          )}
          <div className="w-full md:w-full px-2 py-3 flex justify-end md:justify-center">
            <Button
              title="画像選択"
              color="base-300"
              onClick={() => {
                if (inputRef.current !== null) {
                  inputRef.current.click();
                }
              }}
            />
            <input
              ref={inputRef}
              type="file"
              onChange={(e) => {
                const files = e.target.files;
                if (files !== null) {
                  const file = files[0];
                  upload(file).catch((e) => console.log(e));
                  // 同じファイルを選択したときにonChangeに発火しないのでstate登録後すぐにクリア
                  e.target.value = "";
                }
              }}
              accept="image/*"
              style={{ display: "none" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
