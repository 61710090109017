import classnames from "classnames";
import React, { ReactElement, ReactNode, useState } from "react";
import BaseModal from "./BaseModal";

interface Props {
  title: string;
  messages: string[];
  onClose: () => void;
  isVisible: boolean;
}

export default function MessageModal(props: Props): ReactElement | null {
  const { title, messages, onClose, isVisible } = props;
  if (!isVisible) {
    return null;
  }
  return (
    <BaseModal
      show={true}
      contentLabel="MessageModal"
      onCloseModal={() => {
        onClose();
      }}
      onRequestClose={() => {
        onClose();
      }}
    >
      <BaseModal.Body>
        <div>
          <div className="font-bold">{title}</div>
          <div className="mt-2 text-sm">
            {messages.map((m) => (
              <div key={m}>{m}</div>
            ))}
          </div>
        </div>
      </BaseModal.Body>
    </BaseModal>
  );
}
