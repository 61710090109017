import { CommentResponse } from "./Comment";
import { FileResourceResponse } from "./FileResource";
import { GoodThingDetailResponse } from "./GoodThingDetail";
import { ReactionResponse } from "./Reaction";
import { SelfWorkActionResponse } from "./SelfWorkAction";
import { UserResponse } from "./User";
import { WorkResponse } from "./Work";

interface CommentDetailResponse extends CommentResponse {
  user: UserResponse;
  reactions: Array<ReactionResponse & { user: UserResponse }>;
  file_resources: Array<
    FileResourceResponse & {
      reactions: Array<ReactionResponse & { user: UserResponse }>;
    }
  >;
}

export interface SelfWorkActionDetailResponse extends SelfWorkActionResponse {
  user: UserResponse;
  reactions: Array<ReactionResponse & { user: UserResponse }>;
  comments: CommentDetailResponse[];
  work: WorkResponse;
  file_resources: FileResourceResponse[];
  good_thing_details: GoodThingDetailResponse[];
}

export interface SelfWorkActionMarkedIconType {
  start: string; // date format
  end: string; // date format
  happinesses: string[];
}

export function viewBodies(
  selfWorkAction: SelfWorkActionDetailResponse
): string[] {
  const activeGoodThingDetails = selfWorkAction.good_thing_details.filter(
    (gtd) => gtd.body != null && gtd.body !== ""
  );
  if (activeGoodThingDetails.length === 0) {
    return ["気分を入力しました。"];
  }
  const goodThingDetailBodies = activeGoodThingDetails.map(
    (gtd, index) => `${index + 1}: ${gtd.body}`
  );
  return goodThingDetailBodies;
}
