import React, { ReactElement, useState } from "react";
import "rc-slider/assets/index.css";
import TextAreaField from "../shared/forms/fields/TextAreaField";
import { fieldId, fieldName } from "../../utils/formAttributeUtils";
import { SelfWorkActionEditType } from "../../models/SelfWorkAction";
import HiddenForm from "../shared/forms/HiddenForm";
import HiddenField from "../shared/forms/fields/HiddenField";

interface Props {
  selfWorkAction: SelfWorkActionEditType;
  // onValueChange: (obj: {
  //   good_thing_details: Array<{ body: string }>;
  //   good_thing_details_attributes: Array<{ body: string }>;
  // }) => void;
}

export default function SelfWorkActionGoodThingsForm(
  props: Props
): ReactElement {
  const { selfWorkAction } = props;
  const [goodThingDetails, setGoodThingDetails] = useState(
    selfWorkAction.good_thing_details
  );

  const changeValue = (index: number, text: string): void => {
    const temp = goodThingDetails.concat([]);
    temp.splice(
      index,
      1,
      Object.assign({}, goodThingDetails[index], { body: text })
    );
    setGoodThingDetails(temp);
  };

  const addForm = (): void => {
    const temp = goodThingDetails.concat([]);
    temp.splice(temp.length, 0, { id: null, body: "" });
    setGoodThingDetails(temp);
  };

  const deleteForm = (index: number): void => {
    const temp = goodThingDetails.concat([]);
    temp.splice(
      index,
      1,
      Object.assign({}, goodThingDetails[index], { _destroy: "1" })
    );
    setGoodThingDetails(temp);
  };

  let labelIndex = 0;

  return (
    <div>
      <div className="flex flex-wrap border-b border-gray-300">
        <div className="w-full md:w-1/3 px-2 py-3">良かったこと</div>
        <div className="w-full md:w-2/3 px-2">
          {goodThingDetails.map((gtd, i) => {
            if (gtd._destroy !== undefined && gtd._destroy === "1") {
              return (
                <div key={i}>
                  {gtd.id !== null && (
                    <HiddenField
                      id={fieldId(
                        "self_work_action",
                        "good_thing_details",
                        "id",
                        i
                      )}
                      name={fieldName(
                        "self_work_action",
                        "good_thing_details",
                        "id",
                        i
                      )}
                      value={gtd.id}
                    />
                  )}
                  <HiddenField
                    id={fieldId(
                      "self_work_action",
                      "good_thing_details",
                      "_destroy",
                      i
                    )}
                    name={fieldName(
                      "self_work_action",
                      "good_thing_details",
                      "_destroy",
                      i
                    )}
                    value={1}
                  />
                </div>
              );
            }
            labelIndex = labelIndex + 1;
            return (
              <div key={i}>
                {gtd.id !== null && (
                  <HiddenField
                    id={fieldId(
                      "self_work_action",
                      "good_thing_details",
                      "id",
                      i
                    )}
                    name={fieldName(
                      "self_work_action",
                      "good_thing_details",
                      "id",
                      i
                    )}
                    value={gtd.id}
                  />
                )}
                {i >= 3 && (
                  <div className="flex flex-row justify-end mt-2">
                    <div
                      className="cursor-pointer px-2 py-1"
                      onClick={() => deleteForm(i)}
                    >
                      <i className="far fa-trash-alt text-red-400"></i>
                    </div>
                  </div>
                )}
                <div className="py-2 flex flex-row">
                  <div className="px-3">{labelIndex}</div>
                  <TextAreaField
                    id={fieldId(
                      "self_work_action",
                      "good_thing_details",
                      "body",
                      i
                    )}
                    name={fieldName(
                      "self_work_action",
                      "good_thing_details",
                      "body",
                      i
                    )}
                    value={gtd.body}
                    rows={5}
                    placeholder="例: 昼に食べたうどんがおいしかった"
                    className="w-full border p-2"
                    onChange={(e) => changeValue(i, e.target.value)}
                  />
                </div>
              </div>
            );
          })}
          <div className="flex flex-row justify-end w-full mb-2 mx-2">
            <div
              className="p-2 border rounded cursor-pointer hover:bg-gray-200"
              onClick={addForm}
            >
              入力欄追加
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
