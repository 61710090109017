import axios, { AxiosResponse } from "axios";
import { getRequest } from "./apiClient";

export function keyToUrl(key: string): string {
  return `https://${window.CdnS3BucketHost}/${key}`;
}

export async function getS3PostUrls(
  count: number,
  path: string
): Promise<{
  result?: { urls: Array<{ putUrl: string; getUrl: string }> };
  error?: any;
}> {
  try {
    const { result } = await getRequest<{
      urls: string[];
      get_urls: string[];
    }>("/api/aws/s3_direct_post_urls", { count, path });
    if (result === undefined) {
      return {};
    }
    return {
      result: {
        urls: result.urls.map((u, i) => ({
          putUrl: u,
          getUrl: result.get_urls[i],
        })),
      },
    };
  } catch (error) {
    return { error };
  }
}

// todo: error handling
export async function putImageToS3(
  file: File,
  postUrl: string
): Promise<{ result?: AxiosResponse; error?: any }> {
  const formData = new FormData();
  formData.append("file", file);
  try {
    const result = await axios({
      method: "put",
      url: postUrl,
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });
    return { result };
  } catch (error) {
    return { error };
  }
}

export async function uploadToAws(
  file: File,
  uploadPath = "file_resource"
): Promise<{
  result?: { url: string; key: string; tempUrl: string };
  error?: any;
}> {
  const { result: urlResult, error } = await getS3PostUrls(1, uploadPath);
  if (urlResult === undefined) {
    console.log(error);
    return {};
  }
  const { urls } = urlResult;
  const { result } = await putImageToS3(file, urls[0].putUrl);
  if (result === undefined) {
    return {};
  }
  const {
    config: { url },
  } = result;
  if (url === undefined) {
    return {};
  }
  const cleanUrl = url.replace(/\?.*$/, ""); // パラメータ削除
  // 謎にtypescriptのエラー出る。後から調査
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return {
    result: {
      url: cleanUrl,
      key: cleanUrl.replace(/https:.*\.amazonaws\.com\//, ""),
      tempUrl: urls[0].getUrl,
    },
  };
}
