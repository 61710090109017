import React, { ReactElement, useState } from "react";
import { HappyAiResponse } from "../../models/HappyAi";
import Button from "../shared/Button";
import SelfWorkActionDefaultHappyAiEditModal from "./SelfWorkActionDefaultHappyAiEditModal";

export interface Props {
  happyAis: HappyAiResponse[];
  releasedHappyAis: HappyAiResponse[];
  defaultHappyAi: HappyAiResponse;
  updatePath: string;
  submitButtonId: string;
}

export default function SelfWorkActionDefaultHappyAiEditButton(
  props: Props
): ReactElement {
  const {
    happyAis,
    releasedHappyAis,
    defaultHappyAi: propDefaultHappyAi,
    updatePath,
    submitButtonId,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [defaultHappyAi, setDefaultHappyAi] = useState(propDefaultHappyAi);
  return (
    <>
      {showModal && (
        <SelfWorkActionDefaultHappyAiEditModal
          happyAis={happyAis}
          releasedHappyAis={releasedHappyAis}
          defaultHappyAi={defaultHappyAi}
          updatePath={updatePath}
          submitButtonId={submitButtonId}
          onCloseModal={() => setShowModal(false)}
          afterSaved={(data) => {
            setDefaultHappyAi(data.defaultHappyAi);
            setShowModal(false);
          }}
        />
      )}
      <div className="flex items-center p-1">
        <div className="text-xs text-base-300">{`選択中: ${defaultHappyAi.name}`}</div>
        <div className="ml-2">
          <Button
            size="ex-xs"
            title="編集"
            onClick={() => setShowModal(true)}
          />
        </div>
      </div>
    </>
  );
}
