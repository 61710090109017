import React, { CSSProperties, ReactElement, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export default function BaseModalFooter(props: Props): ReactElement {
  const { children } = props;
  return <div className="p-3 border-t">{children}</div>;
}
